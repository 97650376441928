import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalInformComponent } from './modal-inform/modal-inform.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private dialog: MatDialog
  ) { }

  showDialog(title: string, content: string): Observable<boolean> {

    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      width: '400px',
      data: {title: title, content: content}
    });

    return dialogRef.afterClosed();
  }

  showInformDialog (title: string, content: string): Observable<void> {

    const dialogRef = this.dialog.open(ModalInformComponent, {
      width: '400px',
      data: {title: title, content: content}
    });

    return dialogRef.afterClosed();
  }
}
