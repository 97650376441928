import { StatusEnum } from './status';

export class DependentDocument {
    id: number;
    name: string;
    modifyBy: string;
    modifyDate: string;
    status: StatusEnum;
    description: string;
    parent: string;
    editable: boolean;
    voterAbstain: string;
    voterNo: string;
    voterYes: string;
    voteResult:string;

}