import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTPHeadersInterceptor } from './http-headers.interceptor';
import { ListUsersComponent } from './view/list-users/list-users.component';
import { DocumentDetailsComponent } from './view/documents/document-details/document-details.component';
import { DocumentsComponent } from './view/documents/documents.component';
import { LoginComponent } from './view/unauthenticated/login/login.component';
import { UserModalComponent } from './view/list-users/user-modal/user-modal.component';
import { ModalConfirmComponent } from './shared/services/modal-service/modal-confirm/modal-confirm.component';
import { ModalInformComponent } from './shared/services/modal-service/modal-inform/modal-inform.component';
import { SelectAttachementsComponent } from './shared/components/select-attachements/select-attachements.component';
import {MatMenuModule} from '@angular/material/menu';
import { ChangePasswordComponent } from './view/user-panel/change-password/change-password.component';
import { ChangePasswordOnSuccessComponent } from './view/user-panel/change-password/change-password-on-success/change-password-on-success.component';
import { ResetPasswordComponent } from './view/unauthenticated/login/reset-password/reset-password.component';
import { UnauthenticatedComponent } from './view/unauthenticated/unauthenticated.component';
import { RegisterComponent } from './view/unauthenticated/register/register.component';
import { LoginOnDisabledUserLogInAttemptComponent } from './view/unauthenticated/login/login-on-disabled-user-log-in-attempt/login-on-disabled-user-log-in-attempt.component';
import { RegisterOnSuccessComponent } from './view/unauthenticated/register/register-on-success/register-on-success.component';
import { AutoLogOutModalComponent } from './shared/components/auto-log-out-modal/auto-log-out-modal.component'
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { JwtExpiredInfoComponent } from './shared/components/jwt-expired-info/jwt-expired-info.component';
import { OnPasswordResetComponent } from './view/unauthenticated/login/reset-password/on-password-reset/on-password-reset.component';
import { environment } from 'src/environments/environment';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';

@NgModule({
  declarations: [
    DocumentDetailsComponent,
    DocumentsComponent,
    LoginComponent,
    ListUsersComponent,
    UserModalComponent,
    ModalConfirmComponent,
    ModalInformComponent,
    SelectAttachementsComponent,
    ChangePasswordComponent,
    LoginOnDisabledUserLogInAttemptComponent,
    ChangePasswordOnSuccessComponent,
    ResetPasswordComponent,
    UnauthenticatedComponent,
    RegisterComponent,
    RegisterOnSuccessComponent,
    AutoLogOutModalComponent,
    OnPasswordResetComponent,
    JwtExpiredInfoComponent,
    AppComponent,
    SpinnerComponent
  ],
  entryComponents: [
    UserModalComponent,
    AutoLogOutModalComponent,
    ModalConfirmComponent,
    OnPasswordResetComponent,
    ChangePasswordOnSuccessComponent,
    RegisterOnSuccessComponent,
    LoginOnDisabledUserLogInAttemptComponent,
    JwtExpiredInfoComponent,
    ModalInformComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    MatMenuModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.api.match(/\/\/(.+)\//)[1]],
        blacklistedRoutes: ["http://localhost:4200/api/auth/signin/"],
      },
    }),
    RecaptchaModule,
    RecaptchaFormsModule,

  ],
  exports:[],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HTTPHeadersInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return sessionStorage.getItem("access_token");
}