import { StatusEnum } from './status';
import { DependentDocument } from './dependentDocument';

export class Document {

    id: string;
    number: string;
    name: string;
    createdBy: string;
    createDate: string;
    startDate:string;
    endDate:string;
    remainingTime: string;
    procedureStatus:StatusEnum;
    status: StatusEnum;
    //id: number;
    description: string;
    parent: string;
    votingTransparency: string;
    dependents: DependentDocument[];// DependentDocument[];
    votingType: string;
    // todo - rozgraniczyć attachementy jako nazwy plików i same pliki
    attachments: string[];
    voterAll:string;
    voteResult:string;
    voterAbsent:string;
    voterAbstain:string;
    voterActive:string;
    voterNo:string;
    voterYes:string;
}