import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, PageEvent, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ListaService } from './documents.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StatusEnum } from 'src/app/shared/models/status';
import { ModalWindowMode } from 'src/app/shared/enums/modal-window-mode';
import { DocumentModal, DocumentDetailsComponent } from './document-details/document-details.component';
import { Document } from 'src/app/shared/models/document';
import { parseLongDate } from 'src/app/shared/utils/utils';
import { ModalService } from 'src/app/shared/services/modal-service/modal.service';
import { DocumentDetailsService } from './document-details/document-details.service';
import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { voteResultEnum } from 'src/app/shared/models/voteResults';
import { pageSizeOptionsEnumToArray } from 'src/app/shared/pagination/helper-pagination';
import { SortBy } from 'src/app/shared/interfaces/sort-by';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [ListaService]
})
export class DocumentsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form: FormGroup;
  public dataSource = new MatTableDataSource<Document>();
  displayedColumns: string[] = ['number', 'name', 'createDate', 'startDate', 'endDate', 'votingTransparency', 'votingType', 'cmd'];//default
  listDocumentsData: Document[] = [];
  listDocumentsDataToFilter: Document[] = [];
  roleUserLogged: string = "";

  showAddBtn: boolean = false;

  redBtnPreparation: boolean = false;
  redBtnActive: boolean = false;
  redBtnCompleted: boolean = false;
  redBtnActiveAfterVote: boolean = false;
  redBtnToVote: boolean = false;

  statusSelectBtn: StatusSelectBtn;

  showBtnVote: boolean = false;
  arrayVoteNULL: string[] = [];
  arrayVoteNOTNULL: string[] = [];
  selectedRowIndex: number = -1;

  filterValues = {
    number: '',
    name: '',
    voteResult: '',
    voterAbsent: 0
  };
  voteResultsData: any;
  keys = Object.keys;

  //pagination
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: Number[] = pageSizeOptionsEnumToArray();
  pageEvent: PageEvent = { pageIndex: 0, pageSize: 10, length: 0 };
  sortBy: SortBy = { direction: 'DESC', active: 'number' };
  //https://stackblitz.com/angular/qydbreggmyx?file=src%2Fapp%2Fpaginator-configurable-example.html

  private _unsubscribe1: Subscription;
  private _unsubscribe2: Subscription;
  private _unsubscribe3: Subscription;
  private _unsubscribe4: Subscription;
  private _unsubscribe5: Subscription;
  private _unsubscribe6: Subscription;
  private _unsubscribe7: Subscription;
  private _unsubscribe8: Subscription;
  private _unsubscribe9: Subscription;

  constructor(
    protected router: Router,
    private listaService: ListaService,
    public jwtHelper: JwtHelperService,
    protected dialog: MatDialog,
    protected modalConfirmService: ModalService,
    protected documentService: DocumentDetailsService,
    protected fb: FormBuilder,
    private spinnerService: SpinnerService
  ) {
    this.dataSource.filterPredicate = this.tableFilter();
  }

  ngOnInit() {
    this.form = this.fb.group({
      number: [null],
      name: [null],
      voteResult: [null]
    });
    this.setFilterToComplete();
    this.loadData(this.pageEvent, this.sortBy);

    this.voteResultsData = Object.values(voteResultEnum).filter(f => isNaN(Number(f)));
  }
  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
  }
  ngOnDestroy() {
    this._unsubscribe1 ? this._unsubscribe1.unsubscribe() : null;
    this._unsubscribe2 ? this._unsubscribe2.unsubscribe() : null;
    this._unsubscribe3 ? this._unsubscribe3.unsubscribe() : null;
    this._unsubscribe4 ? this._unsubscribe4.unsubscribe() : null;
    this._unsubscribe5 ? this._unsubscribe5.unsubscribe() : null;
    this._unsubscribe6 ? this._unsubscribe6.unsubscribe() : null;
    this._unsubscribe7 ? this._unsubscribe7.unsubscribe() : null;
    this._unsubscribe8 ? this._unsubscribe8.unsubscribe() : null;
    this._unsubscribe9 ? this._unsubscribe9.unsubscribe() : null;
  }

  loadData(event: PageEvent, sortBy: SortBy) {
    this.spinnerService.openSpinner();
    this.setRole();
    let idUserLogged = this.jwtHelper.decodeToken()['sub'];
    this.arrayVoteNULL = [];
    this.arrayVoteNOTNULL = [];
    this.setPageEvent(event);

    let procedureStatus = "";
    if (this.roleUserLogged == "ROLE_USER" && this.statusSelectBtn == undefined) { procedureStatus = "ACTIVE"; this.statusSelectBtn = StatusSelectBtn.toVote; }

    if (this.roleUserLogged == "ROLE_ADMIN" && this.statusSelectBtn == undefined) { procedureStatus = "IN_PREPARATION"; this.statusSelectBtn = StatusSelectBtn.inPreparation; }

    switch (this.statusSelectBtn) {
      case StatusSelectBtn.inPreparation: {
        procedureStatus = "IN_PREPARATION";
        break;
      }
      case StatusSelectBtn.active: {
        procedureStatus = "ACTIVE";
        break;
      }
      case StatusSelectBtn.toVote: {
        procedureStatus = "ACTIVE";
        break;
      }
      case StatusSelectBtn.complete: {
        procedureStatus = "COMPLETED";
        break;
      }
      default: {
        procedureStatus = "ACTIVE";
      }
    }

    let isVote = false;
    let body = ``;

    if (this.roleUserLogged == "ROLE_USER") {
      if (this.statusSelectBtn == StatusSelectBtn.toVote)//Do zagłosowania
        isVote = false;
      else
        if (this.statusSelectBtn == StatusSelectBtn.complete)//Zakończone
          isVote = true;

      body = this.setBodyFindPage(this.pageEvent, procedureStatus, idUserLogged, sortBy, isVote);

      this._unsubscribe1 = this.documentService.findPageVUserProcedure(body).subscribe(listDocuments => {
        this.listDocumentsData = [];
        for (let index = 0; index < listDocuments.content.length; index++) {
          this.listDocumentsData.push(listDocuments.content[index].procedure);
        }
        this.pageEvent.length = listDocuments.totalElements;
        this.pageEvent.pageIndex = listDocuments.pageable.pageNumber;

        this.dataSource.data = this.listDocumentsData;
        this.displayedColumnsUser();
        this.spinnerService.closeSpinner();
      });
    } else {//when ROLE_ADMIN
      body = this.setBodyFindPage(this.pageEvent, procedureStatus, idUserLogged, sortBy);
      this._unsubscribe2 = this.documentService.findPagePdgoProcedure(body).subscribe(listDocuments => {
        this.listDocumentsData = [];
        Object.assign(this.listDocumentsData, listDocuments.content);

        this.pageEvent.length = listDocuments.totalElements;
        this.pageEvent.pageIndex = listDocuments.pageable.pageNumber;

        this.dataSource.data = this.listDocumentsData;
        this.displayedColumnsAdmin();
        this.spinnerService.closeSpinner();
      });
    }
  }

  setBodyFindPage(event: PageEvent, procedureStatus: string, idUserLogged: any, sortBy: SortBy, isVote?: boolean): string {
    let body = `{
      "filter": {`;

    if (this.filterValues.voteResult != '') {
      body = body + `
        "voteResult": {
          "entityAtributeType": "Enumerator",
          "opertion": "Equal",
          "value": "${this.filterValues['voteResult']}"
        },`;
    }
    if (this.filterValues.name) {
      body = body + `"name": {
        "entityAtributeType": "String",
        "opertion": "Like",
        "value": "${this.filterValues['name']}"
      },`;
    }
    if (this.filterValues.number) {
      body = body + `"number": {
        "entityAtributeType": "String",
        "opertion": "Like",
        "value": "${this.filterValues['number']}"
      },`;
    }

    if (this.roleUserLogged == "ROLE_USER") {
      body = body + `
      "voter.id": {
        "entityAtributeType": "Integer",
        "opertion": "Equal",
        "value": "${idUserLogged}"
        },
      "voted": {
        "entityAtributeType": "Boolean",
        "opertion": "Equal",
        "value": "${isVote}"
        },`;
    }
    if ((this.statusSelectBtn != StatusSelectBtn.complete && this.roleUserLogged == "ROLE_USER") || (this.roleUserLogged == "ROLE_ADMIN" || this.roleUserLogged == "ROLE_SUPERADMIN")) {
      body = body +
        `"procedureStatus": {
          "entityAtributeType": "Enumerator",
          "opertion": "Equal",
          "value": "${procedureStatus}"
        }`;
    } else body = body.slice(0, -1);

    body = body +
      `},
      "sort": {
        "${sortBy.active}": "${sortBy.direction}"
      },
      "pageNumber": ${event.pageIndex},
      "pageSize": ${event.pageSize}

   }`;
    // "votingTransparency": {
    //   "entityAtributeType": "Enumerator",
    //   "opertion": "Equal",
    //   "value": "SECRET"
    // }
    return body;
  }

  displayedColumnsUser() {
    switch (this.statusSelectBtn) {
      case StatusSelectBtn.inPreparation: {//W przygotowaniu
        break;
      }
      case StatusSelectBtn.active: {//W toku
        break;
      }
      case StatusSelectBtn.complete: {//Zakończone
        this.displayedColumns = ['number', 'name', 'startDate', 'endDate', 'procedureStatus', 'voteResult', 'votingTransparency', 'votingType', 'cmd'];
        break;
      }
      case StatusSelectBtn.toVote: {//Do zagłosowania
        this.displayedColumns = ['number', 'name', 'startDate', 'endDate', 'votingTransparency', 'votingType', 'cmd'];
        this.redBtnToVote = true;
        this.showBtnVote = true;
        break;
      }
      case StatusSelectBtn.activeAfterVote: {//Aktywne na ktore zagłosowano
        break;
      }
      default: {
        this.statusSelectBtn = StatusSelectBtn.toVote;
        this.displayedColumns = ['number', 'name', 'startDate', 'endDate', 'votingTransparency', 'votingType', 'cmd'];
        this.redBtnToVote = true;
        this.showBtnVote = true;
      }
    }
  }

  displayedColumnsAdmin() {
    switch (this.statusSelectBtn) {
      case StatusSelectBtn.inPreparation://W przygotowaniu
        {
          this.displayedColumns = ['number', 'name', 'createDate', 'votingTransparency', 'votingType', 'cmd'];
          this.showAddBtn = true;
          this.redBtnPreparation = true;
          break;
        }
      case StatusSelectBtn.active: {//W toku
        this.displayedColumns = ['number', 'name', 'startDate', 'endDate', 'votingTransparency', 'votingType', 'cmd'];
        break;
      }
      case StatusSelectBtn.complete: {//Zakończone
        this.displayedColumns = ['number', 'name', 'startDate', 'endDate', 'procedureStatus', 'voteResult', 'votingTransparency', 'votingType', 'cmd'];
        break;
      }
      case StatusSelectBtn.toVote: {//Do zagłosowania
        break;
      }
      case StatusSelectBtn.activeAfterVote: {//Aktywne na ktore zagłosowano - DO USUNIECIA?
        break;
      }
      default: {
        this.statusSelectBtn = StatusSelectBtn.inPreparation;
        this.displayedColumns = ['number', 'name', 'createDate', 'votingTransparency', 'votingType', 'cmd'];
        //this.showAddBtn = true;
        //this.redBtnPreparation = true;
      }
    }
  }

  setRole() {
    const userRole: string = this.jwtHelper.decodeToken()['auth'];
    this.roleUserLogged = userRole;

    switch (userRole) {
      case "ROLE_SUPERADMIN":
      case "ROLE_ADMIN": {
        this.displayedColumns = ['number', 'name', 'createDate', 'votingTransparency', 'votingType', 'cmd'];
        break;
      }
      case "ROLE_USER": {
        this.displayedColumns = ['number', 'name', 'startDate', 'endDate', 'votingTransparency', 'votingType', 'cmd'];
        break;
      }
      default: {
        throw new Error("Role " + userRole + " is unsupported!");
      }
    }
  }

  addNew() {
    //this.router.navigate(["documents/new"]);
    const model: DocumentModal = {
      document: new Document,// null,
      mode: ModalWindowMode.New,
      roleLoggedUser: this.roleUserLogged,
      showBtnVote: false
    };
    this.openDialog(model);

  }

  onShow(document: Document) {
    const model: DocumentModal = {
      document: document,
      mode: ModalWindowMode.Show,
      roleLoggedUser: this.roleUserLogged,
      showBtnVote: false
    };
    this.openDialog(model);
  }


  onEdit(document: Document) {
    //this.router.navigate(["documents/" + element.id]);
    const model: DocumentModal = {
      document: document,
      mode: ModalWindowMode.Edit,
      roleLoggedUser: this.roleUserLogged,
      showBtnVote: this.showBtnVote

    };
    this.openDialog(model);

  }

  onDelete(document: Document) {
    this._unsubscribe3 = this.modalConfirmService.showDialog('Potwierdzenie', 'Czy chcesz usunąć pozycję?').subscribe(bol => {
      if (bol) {
        this.documentService.delete(document.id).subscribe(data => {
          this.loadData(this.pageEvent, this.sortBy);
        });
      }
    });
  }

  toVote(document: Document) {//ADMIN  Przekaż do głosowania
    if (document.procedureStatus.toString() !== "IN_PREPARATION") {
      this.modalConfirmService.showInformDialog("Uwaga", "Operacja dozwolona tylko dla - W przygotowaniu (IN_PREPARATION)");
      return null;
    }
    if (document.votingType == "FOR_PERSON" && document.dependents.length == 0) {
      this.modalConfirmService.showInformDialog("Uwaga", "Glosowanie Osobowe - proszę wybrać przynajmniej jednego kandydata.");
      return null;
    }

    this._unsubscribe4 = this.modalConfirmService.showDialog('Potwierdzenie', 'Czy chcesz przekazać do głosowania?').subscribe(bol => {
      let startDate = formatDate(new Date, 'yyyy-MM-ddTHH:mm:ss.SSS', 'en-US')
      if (bol) {
        //Object.assign(this.data.document, this.form.value);
        const body = `{
            "startDate": "${startDate}",
            "endDate": null
          }`;
          this._unsubscribe5 = this.documentService.toVote(document.id, body).subscribe(resVote => {
          this.loadData(this.pageEvent, this.sortBy);
        }, (error: HttpErrorResponse) => {
          if (error.status == 500 && error.url.includes("/toVote"))
            this.modalConfirmService.showInformDialog("Uwaga", "Wybierz przynajmniej jedną uprawnioną osobę.");
          console.log(error);
        })
      }
    });
  }

  setFilterToComplete() {//filter for 'Zakonczone'
  this._unsubscribe6 = this.form.controls['number'].valueChanges.subscribe(filtrValue => {
      this.filterValues.number = filtrValue;
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this._unsubscribe7 = this.form.controls['name'].valueChanges.subscribe(filtrValue => {
      this.filterValues.name = filtrValue;
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this._unsubscribe8 = this.form.controls['voteResult'].valueChanges.subscribe(filtrValue => {
      this.filterValues.voteResult = filtrValue == "-1" ? '' : voteResultEnum[filtrValue];
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }

  refreshDataInPreparation() {//W przygotowaniu
    this.filterValues = {
      number: '',
      name: '',
      voteResult: '',
      voterAbsent: 0
    };
    this.dataSource.filter = null;
    this.statusSelectBtn = StatusSelectBtn.inPreparation;
    this.paginator.firstPage();
    this.loadData(this.pageEvent, this.sortBy);

    this.showAddBtn = true;
    this.showBtnVote = false;

    this.redBtnPreparation = true;
    this.redBtnActive = false;
    this.redBtnCompleted = false;
    this.redBtnActiveAfterVote = false;
    this.redBtnToVote = false;
  } ''

  refreshDataActive() {// W toku
    this.filterValues = {
      number: '',
      name: '',
      voteResult: '',
      voterAbsent: 0
    };
    this.dataSource.filter = null;
    this.statusSelectBtn = StatusSelectBtn.active;
    this.paginator.firstPage();
    this.loadData(this.pageEvent, this.sortBy);

    this.showAddBtn = false;
    this.showBtnVote = false;

    this.redBtnPreparation = false;
    this.redBtnActive = true;
    this.redBtnCompleted = false;
    this.redBtnActiveAfterVote = false;
    this.redBtnToVote = false;
  }

  refreshDataCompleted() {//Zakończone
    this.filterValues.number = this.form.controls['number'].value == null ? '' : this.form.controls['number'].value;
    this.filterValues.name = this.form.controls['name'].value == null ? '' : this.form.controls['name'].value;
    this.filterValues.voteResult = this.form.controls['voteResult'].value == "-1" || this.form.controls['voteResult'].value == null ? '' : voteResultEnum[this.form.controls['voteResult'].value];
    //console.log(this.filterValues);//TEST
    this.dataSource.filter = JSON.stringify(this.filterValues);
    this.statusSelectBtn = StatusSelectBtn.complete;
    this.paginator.firstPage();
    this.loadData(this.pageEvent, this.sortBy);

    this.showAddBtn = false;
    this.showBtnVote = false;

    this.redBtnPreparation = false;
    this.redBtnActive = false;
    this.redBtnCompleted = true;
    this.redBtnActiveAfterVote = false;
    this.redBtnToVote = false;
  }

  refreshDataToVote() {//Do zagłosowania
    this.filterValues = {
      number: '',
      name: '',
      voteResult: '',
      voterAbsent: 0
    };
    this.dataSource.filter = null;
    this.statusSelectBtn = StatusSelectBtn.toVote;
    this.paginator.firstPage();
    this.loadData(this.pageEvent, this.sortBy);

    this.showBtnVote = true;

    this.redBtnPreparation = false;
    this.redBtnActive = false;
    this.redBtnCompleted = false;
    this.redBtnActiveAfterVote = false;
    this.redBtnToVote = true;
  }

  refreshDataActiveAfterVote() {//Aktywne na które zagłosowano - DO USUNIECIA?
    // this.statusSelectBtn = StatusSelectBtn.activeAfterVote;
    // this.loadData(this.pageEvent, this.sortBy);
    // this.dataSource.data = this.listDocumentsData.filter(i => this.arrayVoteNOTNULL.includes(i.id));

    // this.redBtnPreparation = false;
    // this.redBtnActive = false;
    // this.redBtnCompleted = false;
    // this.redBtnActiveAfterVote = true;
    // this.redBtnToVote = false;

    // this.showBtnVote = false;
  }
  tableFilter(): (data: Document, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      Object.keys(data).forEach(key => {
        if (data[key] === null) {
          data[key] = '';
        }
      });
      const searchTerms = JSON.parse(filter);

      return data.name.toString().toLowerCase().indexOf(searchTerms.name.toLowerCase()) !== -1
        && data.number.toString().toLowerCase().indexOf(searchTerms.number.toLowerCase()) !== -1
    };
    return filterFunction;
  }

  parseLongDate(date) {
    return parseLongDate(date);
  }

  colorRow(row) {
    this.selectedRowIndex = row.id;
  }

  setStatusOnView(procedureStatus: string) {
    switch (StatusEnum[procedureStatus].toString()) {
      case StatusEnum.ACTIVE.toString():
        return "Aktywny";
      case StatusEnum.IN_PREPARATION.toString():
        return "Roboczy";
      case StatusEnum.COMPLETED.toString():
        return "Zakończony";
      default:
        return "";
    }
  }

  setVoteResultOnView(voteResult: string) {
    switch (voteResult) {
      case "ACCEPTED":
        return "Przyjęte";
      case "REJECTED":
        return "Odrzucone";
      default:
        return "";
    }
  }

  openDialog(documentModal: DocumentModal) {
    const dialogRef = this.dialog.open(DocumentDetailsComponent, {
      //width: '600px',
      maxHeight: '85vh',
      maxWidth: '85vw',
      data: documentModal,
    });

    const sub = dialogRef.componentInstance.onDataSend.subscribe(() => {
      this.loadData(this.pageEvent, this.sortBy);
    });

    this._unsubscribe9 = dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
      //this.loadData(this.pageEvent, this.sortBy);
    });

  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {//pagination
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }
  setPageEvent(event: PageEvent) {
    this.pageEvent.length = event.length;
    this.pageEvent.pageIndex = event.pageIndex;
    this.pageEvent.pageSize = event.pageSize;
  }
  private sortData(sortField) {
    if (this.sort.direction == "") {
      this.sortBy.direction = "DESC";
      this.sortBy.active = "number";
    } else {
      this.sortBy.direction = this.sort.direction.toUpperCase();
      this.sortBy.active = sortField != undefined ? sortField : this.sort.active;
    }

    this.loadData(this.pageEvent, this.sortBy);
  }

}

export enum StatusSelectBtn {
  inPreparation = 0,// - W przygotowaniu
  active = 1, // - W toku
  toVote = 2, // - Do zaglosowania
  activeAfterVote = 3, // - Aktywne na które zagłosowano
  complete = 4 //Zakonczone
}
