import { Injectable } from '@angular/core';
import { FileDescription } from '../components/models/file-description';
import { Entity } from '../components/models/entity';
import { Attachement } from '../components/models/attachment';

@Injectable({
  providedIn: 'root'
})
export class FormDataAttachementsService {

  jsonParam = 'jsonFormData';
  fileParam = 'files';
  descriptionParam = 'jsonFileDescriptions';
  attachementsToUpdate = 'jsonAttachments4Update';

  constructor() { }


  getFormData(filesDesc: FileDescription[], entity: Entity): FormData {
    const input = new FormData();

    const json = JSON.stringify(entity);
    const files = filesDesc.map(x => x.file);
    const descriptions = filesDesc.map(x => x.description);

    input.append(this.jsonParam, json);

    if (filesDesc.length) {
      for (let i = 0; i < files.length; i++) {
        input.append(this.fileParam, files[i]);
      }
      input.append(this.descriptionParam, JSON.stringify(descriptions));
    } else {
      input.append(this.fileParam, null);
      input.append(this.descriptionParam, '');
    }

    return input;
  }

  updateFormData(filesDesc: FileDescription[], entity: Entity, updateAttachement: Attachement[] ): FormData {
    const input: FormData = this.getFormData(filesDesc, entity);

    if (updateAttachement.length) {
      input.append(this.attachementsToUpdate, JSON.stringify(updateAttachement));
    } else {
      input.append(this.attachementsToUpdate, '');
    }
    // send just changed attachemnt description
    return input;
  }
}
