import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Document } from 'src/app/shared/models/document';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { getHeadersWithToken } from 'src/app/shared/services/helper-service';
import { Attachement } from 'src/app/shared/components/models/attachment';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DocumentDetailsService {

  constructor(private http: HttpClient) { }

  create(document: Document): Observable<Document> {
    const url = environment.api + "/PdgoProcedure/";
    return this.http.post<Document>(url, document);
  }

  update(document: Document): Observable<Document> {
    const url = environment.api + '/PdgoProcedure/' + document.id;
    return this.http.put<Document>(url, document);
  }

  delete(id: string): Observable<Document> {
    const url = environment.api + '/PdgoProcedure/' + id;
    return this.http.delete(url) as Observable<Document>;
  }

  public toVote(id: string, body: string): Observable<Document[]> {
    return this.http.post<Document[]>(environment.api + '/PdgoProcedure/' + id +'/toVote', body, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<Document[]>;
  }

  public vote(id: string, body: string): Observable<Document[]> {
    return this.http.post<Document[]>(environment.api + '/PdgoProcedure/' + id +'/vote', body, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<Document[]>;
  }

  public voteMultiPerson(id: string, body: string): Observable<Document[]> {
    return this.http.post<Document[]>(environment.api + '/PdgoProcedure/' + id +'/multiVote', body, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<Document[]>;
  }

  public findPagePdgoProcedure(body: string): Observable<any> {
    return this.http.post<any>(environment.api + '/PdgoProcedure/findPage', body, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any>;
  }

  public findPageVUserProcedure(body: string): Observable<any> {
    return this.http.post<any>(environment.api + '/VUserProcedure/findPage', body, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any>;
  }

  public setUsersIdsToDocIdVote(id: string, body: string): Observable<any[]> {
    return this.http.put<any[]>(environment.api + '/PdgoProceduresAuthorized/' + id, body, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any[]>;
  }

  public getUsersIdsToDocIdVote(): Observable<any[]> {
    return this.http.get<any[]>(environment.api + '/PdgoProceduresAuthorized', { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any[]>;
  }

  public getUsersIdsToDocIdVoteByID(idDocument: string): Observable<any[]> {
    return this.http.get<any[]>(environment.api + '/PdgoProceduresAuthorized/'+idDocument, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any[]>;
  }
  public createUsersIdsToDocIdVote(body: string): Observable<any[]> {
    return this.http.post<any[]>(environment.api + '/PdgoProceduresAuthorized', body, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any[]>;
  }

  public getInfoVoteUsersIdDocId(documentId: string, userId: string): Observable<any> {
    return this.http.get<any>(environment.api + '/PdgoProceduresVote/id/'+documentId+'/'+userId, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any>;
  }

  public getInfoMultiVoteForPerson(documentId: string, userId: string): Observable<any[]> {
    return this.http.get<any[]>(environment.api + '/PdgoProceduresVote/multi/'+documentId+'/'+userId, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any[]>;
  }

  public getInfoVoteByDocId(documentId: string): Observable<any> {
    return this.http.get<any>(environment.api + '/PdgoProceduresVote/Procedure/'+documentId, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any>;
  }

  public deleteUsersIdsToDocIdVote(procedureId: string, userId: string): Observable<any[]> {
    return this.http.delete<any[]>(environment.api + '/PdgoProceduresAuthorized/id/'+procedureId+'/'+userId, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any[]>;
  }

  public deleteAttachment(id: number, attachment: Attachement): Observable<any> {
    const endpoint = "/PdgoProcedure/" + id + "/attachements/" + attachment.id;
    return this.http.delete(environment.api + endpoint);
  }

  public downloadAttachment(attachment: Attachement) {

  }

  updateWithAtachments(entityId: number, formData: FormData): Observable<any> {
    const endpoint = "/PdgoProcedure/" + entityId + "/attachements";
    return this.http.post(environment.api + endpoint, formData);
  }

  getAttachements(id: string): Observable<Attachement[]> {
    const endpoint = "/PdgoProcedure/" + id + "/attachements";
    return this.http.get<Attachement[]>(environment.api + endpoint);
  }


  createWithAttachements(formData: FormData): Observable<any> {
    const endpoint = "/PdgoProcedure/attachements";
    return this.http.post(environment.api + endpoint, formData);
  }

  getDownloadAttachement(id: number, idAttachement: number): void {
    const url = environment.api + "/PdgoProcedure/" + id + "/attachements" + `/${idAttachement}`;
    this.http.get(url,
      {
        responseType: 'blob',
        observe: 'response'
      })
      .subscribe(data => {
        const disposition = data.headers.get('Content-Disposition');

        const startIndex = disposition.indexOf('filename=') + 10;
        const endIndex = disposition.length - 1;
        const fileName = disposition.substring(startIndex, endIndex);

        FileSaver.saveAs(data.body, fileName);
      });
  }
}
