export class ChangePassword {
    oldPassword: string;
    password: string;
    passwordRepeat: string;

    constructor(oldPassword: string, password: string, passwordRepeat: string) {
        this.oldPassword = oldPassword;
        this.password = password;
        this.passwordRepeat = passwordRepeat;
    }
}