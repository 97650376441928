import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReCaptchaService {

  constructor(private http: HttpClient) { }

  sendToken(token){
    const url = environment.api + "/public/register/verifyCaptcha";
    return this.http.post<any>(url, {data: token})
  }
}
