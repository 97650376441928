import { Component, OnInit, Input } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input()
  spinning = false;

  private _unsubscribe1: Subscription;

  constructor(protected spinnerService: SpinnerService) { }

  ngOnInit() {
    this._unsubscribe1 = this.spinnerService.spinnerState$.subscribe((val: boolean) => {
      this.spinning = val;
    })
  }

  ngOnDestroy() {
    this._unsubscribe1 ? this._unsubscribe1.unsubscribe() : null;
  }

}
