import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ResetPasswordService } from './reset-password.service';
import { MatDialog } from '@angular/material';
import { OnPasswordResetComponent } from './on-password-reset/on-password-reset.component';
import { ReCaptchaService } from '../../re-captcha.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers:  [ ReCaptchaService ]
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  errMsg: string;
  reCaptchaFailed: boolean = true;
  siteKeyReCaptcha: string = environment.siteKeyReCaptcha; //localhost

  constructor(protected fb: FormBuilder,
    private resetPasswordService: ResetPasswordService,
    protected dialog: MatDialog,
    protected router: Router,
    private reCaptchaService: ReCaptchaService
   ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ["", Validators.email]
    });
  }

  resetPassword() {
    const email = this.form.value.email;
    this.resetPasswordService.resetPassword(email).then(
      result => {        
        const dialogRef = this.dialog.open(OnPasswordResetComponent);
        dialogRef.afterClosed().subscribe(() => this.router.navigateByUrl("/"));        
      },
      reason => this.errMsg = reason.error
    );
  }
  
  isEmailInvalid(): boolean {
    const result = this.form.controls.email.invalid;
    if (result) {
      this.errMsg = "";
    }
    return result;
  }

  isEmailEnteredAndValidated(): boolean {
    const enteredEmail: string = this.form.controls.email['value'];
    return this.form.controls.email.valid && (enteredEmail.length > 0) && !this.reCaptchaFailed;
  }
  
  async resolved(reCaptchaResponse) {
    await this.sendTokenToBackend(reCaptchaResponse);
  }
  
  sendTokenToBackend(token){
    //calling the service and passing the token to the service
    this.reCaptchaService.sendToken(token).subscribe(
      data => {
        this.reCaptchaFailed = false;
      },
      err => {
        this.reCaptchaFailed = true;
      },
      () => {}
    );
  }
}
