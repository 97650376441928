import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FileDescription } from '../models/file-description';
import { Attachement } from '../models/attachment';
import { SelectAttachementsService } from '../../services/select-attachments.service';
import { ModalService } from '../../services/modal-service/modal.service';

@Component({
  selector: 'egdpr-select-attachements',
  templateUrl: './select-attachements.component.html',
  styleUrls: ['./select-attachements.component.scss']
})
export class SelectAttachementsComponent implements OnInit {

  @Input()
  filesDescription: FileDescription[];

  @Input()
  attachements: Attachement[];

  @Input()
  isNew: boolean;

  @Input()
  isShowDetail: boolean;

  @Input()
  isReadOnly: boolean;

  @Output()
  delFile = new EventEmitter<Attachement>();

  @Output()
  getFile = new EventEmitter<Attachement>();


  @ViewChild('inputFile') inputVar: ElementRef;


  constructor(
    protected serv: SelectAttachementsService,
    protected modalConfrimService: ModalService
    ) { }

  ngOnInit() {

  }

  showWarning(warn: string): void {
    this.modalConfrimService.showInformDialog('Błąd dodawania załącznika', warn).subscribe();
  }

  addFile(file: File) {
    const fileDescription: FileDescription = { file: file, description: null };
    this.filesDescription.push(fileDescription);
    this.inputVar.nativeElement.value = '';
  }


  fileEvent(event) {
    const file: File = event.target.files[0];

    const msg = this.serv.checkFile(file);
    if (msg) {
      this.showWarning(msg);
      this.inputVar.nativeElement.value = '';
    } else {
      this.addFile(file);
    }
  }

  deleteFile(element: FileDescription) {
    this.modalConfrimService.showDialog('attachements_confirmation', 'attachements_delete_file').subscribe(bol => {
      if (bol) {
        this.serv.subSumFileSizes(element.file.size);
        const idx = this.filesDescription.indexOf(element);
        if(idx != -1) {
          this.filesDescription.splice(idx, 1);
        }
      }
    });
  }

  onChangeDescriptionFile(val: string, item: FileDescription) {
    item.description = val;
  }

  deleteAttachement(element: Attachement) {
    this.modalConfrimService.showDialog('attachements_confirmation', 'attachements_delete_file').subscribe(bol => {
      if (bol) {
        this.attachements = this.attachements.filter(el => el !== element);
        this.delFile.emit(element);
      }
    });
  }

  onChangeDescriptionAttachement(val: string, item: Attachement) {
    item.description = val;
  }

  downloadAttachement(element: Attachement) {
    this.getFile.emit(element);
  }
}

