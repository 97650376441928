import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutIntervalService {  
  private intervaler: ReturnType<typeof setInterval>;
  timeForDecision: number = environment.autoLogoutTimeoutInSeconds * 1000;
  private promise: Promise<any>;
  private timeleft: number;

  constructor() { }

  countDown(): Promise<any> {  
    this.promise =new Promise ((resolve, reject) => { 
      this.timeleft = this.timeForDecision;    
      this.intervaler = setInterval(
        () => {
          this.timeleft -= 1000;
          if(this.timeleft <= 0) {
            resolve(true);
          }
        }, 
        1000
      );
    });

    return this.promise;
  }

  stopCountingDown() {
    clearInterval(this.intervaler);
    Promise.race([() => {}, this.promise]).then(() => { });
  }
}
