import { formatDate } from '@angular/common';


export function parseLongDate(dateInExtendedFormat: string) {
    if (dateInExtendedFormat) {
      const formattedDate = formatDate(dateInExtendedFormat, 'yyyy-MM-dd, HH:mm:ss', 'en-GB');
      if (formattedDate.includes('1970-01-01')) {
        return '';
      } else {
        return formattedDate;
      }
    } else {
      return null;
    }
  }
  