import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-on-disabled-user-log-in-attempt',
  templateUrl: './login-on-disabled-user-log-in-attempt.component.html',
  styleUrls: ['./login-on-disabled-user-log-in-attempt.component.scss']
})
export class LoginOnDisabledUserLogInAttemptComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
