import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import {MatDialog} from '@angular/material';
import { AutoLogoutIntervalService } from './auto-logout-interval.service';

@Component({
  selector: 'app-auto-log-out-modal',
  templateUrl: './auto-log-out-modal.component.html',
  styleUrls: ['./auto-log-out-modal.component.scss']
})
export class AutoLogOutModalComponent implements OnInit {
  timeleft: number;
  intervaler: ReturnType<typeof setInterval>;

  constructor(private auth: AuthService,
    private dialogRef: MatDialog,
    private autoLogoutIntervalService: AutoLogoutIntervalService) {
  }

  ngOnInit() {
    this.timeleft = this.autoLogoutIntervalService.timeForDecision;
    this.intervaler = setInterval(() => {
      this.timeleft -= 1000;
    }, 1000);
    this.autoLogoutIntervalService.countDown().then( () => {
      this.dialogRef.closeAll();
      this.auth.logout();
    });
  }

}
