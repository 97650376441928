import {MatPaginatorIntl} from '@angular/material';
import { PageSizeOptionsPagination } from '../enums/page-size-pagination';

export function pageSizeOptionsEnumToArray(): Number [] {
  let arrNumber: Number[] = [];
  let keys = Object.values(PageSizeOptionsPagination);
  for (let val of keys) {
    if (!isNaN(parseInt(val, 10))) {
      arrNumber.push(parseInt(val, 10));
    }
  }
  return arrNumber;
}


const rangeLabel = (pageIndex: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return `0 z ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = pageIndex * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} z ${length}`;
};

export function getNewPaginatorIntl() {
  //https://stackblitz.com/edit/angular-5mgfxh?file=app%2Fpaginator-overview-example.html
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = "Ilość na stronie";
  // paginatorIntl.nextPageLabel = '';
  // paginatorIntl.previousPageLabel = '';
  paginatorIntl.getRangeLabel = rangeLabel;

  return paginatorIntl;
}
