import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { User } from '../../shared/models/user';
import { Role } from '../../shared/models/role';
import { getHeadersWithToken } from 'src/app/shared/services/helper-service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  public getCollection(): Observable<User[]> {
    return this.http.get<User[]>(environment.api + '/User', { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<User[]>;
  }

  public createUser(user: User): Observable<User[]> {
    return this.http.post<User[]>(environment.api + '/User', user, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<User[]>;
  }

  public findPageGetUsers(body: any): Observable<any> {
    return this.http.post<any>(environment.api + '/User/findPage', body, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<any>;
  }

  public updateUser(user: User): Observable<User[]> {
    return this.http.put<User[]>(environment.api + '/User/' + user.id, user, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<User[]>;
  }

  // public deleteUser(user: User): Observable<User[]> {
  //   return this.http.delete<User[]>(environment.api + '/User/'+user.id, { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
  //     catchError((error: any) => {
  //       return throwError(error);
  //     })
  //   ) as Observable<User[]>;
  // }

  deleteUser(user: User): Promise<any> {
    return this.http.delete<any>(environment.api + '/User/' + user.id).toPromise();
  }

  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(environment.api + '/Role/companyOnly', { headers: getHeadersWithToken(), responseType: 'json' }).pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ) as Observable<Role[]>;
  }

  // public getCollection1(): Observable<User[]> {
  //   return this.http.get<User[]>(environment.api + '/User',{ headers: getHeadersWithToken() });
  // }



}
