import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly accessToken = 'access_token';

  logout$: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient,
    protected router: Router,
    public jwtHelper: JwtHelperService) { }

  logIn(username: string, password: string): Promise<any> {
    return this.http.post<any>(environment.api + '/auth/signin', { username: username, password: password }).toPromise();
  }

  sendToken(token: string): void {
    sessionStorage.setItem(this.accessToken, token)
  }

  getToken(): string {
    return sessionStorage.getItem(this.accessToken);
  }

  getExpirationDate() {
    return this.jwtHelper.getTokenExpirationDate();
  }

  isLoggedIn(): boolean {
    return this.getToken() !== null;

  }
  logout(): void {
    this.router.navigate(['/']).then((value) => {
      if(value) {
        sessionStorage.removeItem(this.accessToken);
      }
    });
  }
}
