import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Param } from '../components/models/param';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SelectAttachementsService {

  temporaryExt = '';
  temporarySize = 0;
  maxRequestSize = 0;
  regExpress: RegExp;

  tabWarn: string[] = [
    'Dopuszczalne rozszerzenia plików: ',
    'Maksymalny dopuszczalny rozmiar pliku: ',
    'Maksymalny rozmiar żądania: '
  ];

  sumSizeFiles = 0;

  constructor(
    private http: HttpClient
  ) {
    this.getParams();
  }
  
  getParamfindByIdIn(ids: string[]): Observable<Param[]> {
    const endpoint = '/Param/findByIdIn';
    return this.http.post<Param[]>(environment.api + endpoint, ids);
  }


  getParams() {
    this.getParamfindByIdIn(['max-file-size', 'max-request-size', 'available-file-extensions'])
    .subscribe(data => {
      data.forEach(el => {
        switch (el.id) {
          case 'max-file-size': {
            this.temporarySize = Number(el.value);
            this.tabWarn[1] += Number(el.value) / 1048576 + 'MB';
            break;
          }
          case 'max-request-size': {
            this.maxRequestSize = Number(el.value);
            this.tabWarn[2] += Number(el.value) / 1048576 + 'MB';
            break;
          }
          case 'available-file-extensions': {
            this.temporaryExt = el.value;
            this.regExpress = this.createExpression();
            this.tabWarn[0] += el.value.split('|').join(', ');
            break;
          }
          default: {
            break;
          }
        }
      });
    });
  }

  createExpression(): RegExp {
    const strExpr = '([A-Za-z0-9\s_\\-\\.\(\):])+(\.)+(' + this.temporaryExt + ')$';
    return new RegExp(strExpr);
  }

  checkExpression(fileName: string): boolean {
    return this.regExpress.test(fileName);
  }

  checkSize(size: number): boolean {
    return size < this.temporarySize;
  }

  checkFile(file: File): string {
    const tres: number[] = [];

    if (!this.checkExpression(file.name)) {
      tres.push(1);
    }
    if (!this.checkSize(file.size)) {
      tres.push(2);
    }
    if (!this.checkMaxFileSizes(file.size)) {
      tres.push(3);
    }

//  prepare warn message
    if (tres.length) {
      let msg = '';
      tres.forEach( (val: number) => {
        msg += this.tabWarn[val - 1];
        msg += '<br>';
      });
      return msg;
    } else {
      this.addSumFileSizes(file.size);
      return null;
    }
  }

  addSumFileSizes(num: number) {
    this.sumSizeFiles += num;
  }

  subSumFileSizes(num: number) {
    this.sumSizeFiles -= num;
  }

  checkMaxFileSizes(num: number) {
    const temp = num + this.sumSizeFiles;
    return temp < this.maxRequestSize;
  }
}
