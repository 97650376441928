import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ChangePassword } from 'src/app/shared/components/models/changePassword';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  readonly url: string = environment.api + "/User/password";

  constructor(private http: HttpClient) { }

  changePassword(oldPassword: string, password: string, passwordRepeat: string): Promise<any> {
    const body: ChangePassword = new ChangePassword(oldPassword, password, passwordRepeat);
    return this.http.put(this.url, body).toPromise();
  }
}
