import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ListaService {

  constructor(private http: HttpClient) {
  }

  getLista(): Promise<Document[]> {
    return this.http.get<Document[]>(environment.api + '/PdgoProcedure').toPromise();
  }

}
