import {NgModule} from '@angular/core';

import {
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatListModule,
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatCardModule,
  MatTreeModule,
  MatSelectModule,
  MatExpansionModule,
  MatGridListModule,
  MatTableModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatTooltipModule,
  MatSortModule,
  MatRadioModule,
  MatTabsModule,
  MatSnackBarModule,
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  NativeDateAdapter,
  MatPaginatorModule,
  MatPaginatorIntl,
  MatProgressSpinnerModule
} from '@angular/material';
import { getNewPaginatorIntl } from './shared/pagination/helper-pagination';


const MY_DATE_FORMATS = {
  parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

export class MyDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          return year + '-' + this._to2digit(month) + '-' + this._to2digit(day);
  }

  private _to2digit(n: number) {
      return ('00' + n).slice(-2);
  }
}

@NgModule({
  imports: [
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatTreeModule,
    MatSelectModule,
    MatExpansionModule,
    MatGridListModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatSortModule,
    MatRadioModule,
    MatTabsModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule
  ],
  exports: [
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatTreeModule,
    MatSelectModule,
    MatExpansionModule,
    MatGridListModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTooltipModule,
    MatSortModule,
    MatRadioModule,
    MatTabsModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule
  ],
  providers: [MatNativeDateModule,
    {provide: DateAdapter, useClass: MyDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    {provide: MatPaginatorIntl, useValue: getNewPaginatorIntl()}
  ]
})
export class MaterialModule {}

