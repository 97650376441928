import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { MatDialog } from '@angular/material';
import { LoginOnDisabledUserLogInAttemptComponent } from './login-on-disabled-user-log-in-attempt/login-on-disabled-user-log-in-attempt.component';
import { ReCaptchaService } from '../re-captcha.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  
  // TODO register new reCaptchaKey
  siteKeyReCaptcha: string = environment.siteKeyReCaptcha; //localhost
  reCaptchaFailed: boolean = true;
  failedLoginAttempt: number = 0;

  // dwa atrybuty do usuniecia
  returnUrl: string;
  loading = false;

  errMsg: string;

  constructor(protected fb: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    private auth: AuthService,
    private reCaptchaService: ReCaptchaService
   ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      password: [null, Validators.required]
    });
  }

  isAnyFieldEmptyOrFormInvalid(): boolean {
    let enteredData = Object.values(this.form.getRawValue());
    if (this.reCaptchaRequired()) {      
      return enteredData.includes(null) || enteredData.includes("") || this.reCaptchaFailed ;
    }
    else {
      return enteredData.includes(null) || enteredData.includes("");
    }
  }

  logIn(): Promise<any> {
    const username = this.form.value.name;
    const password = this.form.value.password;

    return this.auth.logIn(username, password)
      .then(response => {
        this.disableReCaptcha();
        if (response && response.accessToken) {
          this.auth.sendToken(response.accessToken);
        }
        this.router.navigate(["/documents"]);
      }, reason => {
        this.failedLoginAttempt++;
        if (this.failedLoginAttempt == 5) {
          this.enableReCaptcha();
        }
        const error = reason.error['message'];
        switch (error) {
          case 'Bad credentials': {
            this.errMsg = 'Nieprawidłowy login i/lub hasło';
            break;
          }
          case 'konto nie zostało jeszcze aktywowane.': {
            const dialogRef = this.dialog.open(LoginOnDisabledUserLogInAttemptComponent);    
            dialogRef.afterClosed().subscribe(() => {});
            break;
          }
          default: {
            console.error(error);
            this.errMsg = "Nieznany błąd";
          }
        }
      });
  }

  reCaptchaRequired(): boolean {
    return sessionStorage.getItem('re_captcha') != null;
  }

  enableReCaptcha() {
    sessionStorage.setItem('re_captcha', 'required');
  }

  disableReCaptcha() {
    sessionStorage.removeItem('re_captcha');
  }

  async resolved(reCaptchaResponse) {
    await this.sendTokenToBackend(reCaptchaResponse);
  }
  
  sendTokenToBackend(token){
    //calling the service and passing the token to the service
    this.reCaptchaService.sendToken(token).subscribe(
      data => {
        this.reCaptchaFailed = false;
      },
      err => {
        this.reCaptchaFailed = true;
      },
      () => {}
    );
  }
}