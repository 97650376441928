import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Role } from 'src/app/shared/models/role';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from 'src/app/shared/models/user';
import { UserService } from 'src/app/view/list-users/user.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { MustMatch } from 'src/app/_helpers/must-match.validator';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(250, style({opacity:1})),        
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({opacity:0})) 
      ])
    ])
  ],
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  errMsg: string = "";
  form: FormGroup;
  userStatusData: String[] = ['EXPIRED', 'ACTIVE'];
  onAdd = new EventEmitter();
  rolesData: Role[];

  constructor(
    protected userService: UserService,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<UserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModal) { }

  ngOnInit() {
    this.form = this.fb.group({
        name: [null, Validators.required],
        username: [null, Validators.required],
        newPassword: [null, [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]],
        renewPassword: [null, Validators.required],
        email: [null, [Validators.required, Validators.email]],
        userStatus: [null, Validators.required],
        roles: [null, Validators.required]
    }, {
      validator: MustMatch('newPassword', 'renewPassword')
    });

    if(this.data.edit) {
      console.log("PRZED", this.form.controls);
      this.form.controls['newPassword'].clearValidators();
      this.form.controls['renewPassword'].clearValidators();
      console.log("PO", this.form.controls);
    }

    if (this.data.user) {
      this.form.patchValue(this.data.user);
      if (this.data.show) {
        this.form.disable();
      }
    }
    this.loadData();
  }

  loadData() {
    this.userService.getRoles().subscribe(data => {
      this.rolesData = data.filter(r => (r.name == "ROLE_ADMIN" || r.name == "ROLE_USER" || r.name == "ROLE_SUPERADMIN"));
      if (this.data.edit || this.data.show)
        this.form.controls['roles'].setValue(this.data.user.roles[0]);
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onEdit() {
    Object.assign(this.data.user, this.form.value);
    this.data.user.roles = [];
    this.data.user.roles.push(this.form.controls["roles"].value);
    this.userService.updateUser(this.data.user).subscribe(data => {
      this.onAdd.emit();
      this.onClose();
    });
  }

  onNew() {
    let modelUser: User = {} as User;

    Object.assign(modelUser, this.form.value);
    // if (modelUser.newPassword != modelUser.renewPassword) {
    //   this.errMsg = "Hasło powtórzono nieprawidłowo";
    //   return;
    // }
    modelUser.roles = [];
    modelUser.roles.push(this.form.controls["roles"].value);
    this.userService.createUser(modelUser).subscribe(
      response => {
        this.onAdd.emit();
        this.onClose();
      },
      reason => {      
        const error = reason.error['message'];
        const errors = reason.error['errors'];
        if (errors) {
          if (errors[0]['defaultMessage'] == "must be a well-formed email address") {
            this.errMsg = "Wprowadzono adres e-mail w nieprawidłowym formacie";
          }
          else {
            this.errMsg = "";
            errors.forEach(error => {
              this.errMsg += error['defaultMessage'] + "; ";
            });
          }
        }
        else {
          if (error == "register.password.not.matched") {
            this.errMsg = "Błędnie powtórzone hasło"
          }
          else {
            this.errMsg = error;
          }
        }
      }
    );
  }
  

  minimumCharactersNumber(passwordLength: number) {
    const password: string = this.form.controls['newPassword'].value;
    return password.length >= passwordLength;
  }

  containsUpperCaseLetter() {
    const password: string = this.form.controls['newPassword'].value;
    return password.search(/[A-Z]/) > -1;
  }

  containsLowerCaseLetter() {
    const password: string = this.form.controls['newPassword'].value;
    return password.search(/[a-z]/) > -1;
  }

  containsDigit() {
    const password: string = this.form.controls['newPassword'].value;
    return password.search(/\d/) > -1;
  }

  containsSpecialCharacter() {
    const password: string = this.form.controls['newPassword'].value;
    return password.search(/[#?!@$%^&*-]/) > -1;
  }

}

export class UserModal {
  user: User;
  edit: boolean;
  show: boolean;
}
