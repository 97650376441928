import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerSubject$ = new BehaviorSubject<any>(false);

  get spinnerState$(): Observable<boolean> {
    return this.spinnerSubject$.asObservable();
  }

  constructor() { }

  private setSpinnerState(state: boolean): void {
    this.spinnerSubject$.next(state);
  }

  openSpinner(): void {
    this.setSpinnerState(true);
  }

  closeSpinner(): void {
    this.setSpinnerState(false);
  }


}
