import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/view/list-users/user.service';
import { User } from 'src/app/shared/models/user';
import { MatTableDataSource, MatDialog, MatSort, PageEvent } from '@angular/material';
import { Location } from '@angular/common';
import { UserModal, UserModalComponent } from './user-modal/user-modal.component';
import { Role } from 'src/app/shared/models/role';
import { ModalService } from 'src/app/shared/services/modal-service/modal.service';
import { pageSizeOptionsEnumToArray } from 'src/app/shared/pagination/helper-pagination';
import { SortBy } from 'src/app/shared/interfaces/sort-by';
import { Subscription } from 'rxjs';
import { StatusUserEnum } from 'src/app/shared/enums/status';
import { RoleEnum } from 'src/app/shared/enums/role-enum';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
})
export class ListUsersComponent implements OnInit {

  displayedColumns: string[] = ['name', 'username', 'email', 'roles', 'userStatus', 'cmd'];
  @ViewChild(MatSort) sort: MatSort;
  public dataSource = new MatTableDataSource<User>();
  users = [] as User[];

  //pagination
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: Number[] = pageSizeOptionsEnumToArray();
  pageEvent: PageEvent = { pageIndex: 0, pageSize: 10, length: 0 };
  sortBy: SortBy = { direction: 'DESC', active: 'name' };

  private _unsubscribe1: Subscription;
  private _unsubscribe2: Subscription;
  private _unsubscribe3: Subscription;
  private _unsubscribe4: Subscription;
  private _unsubscribe5: Subscription;
  private _unsubscribe6: Subscription;

  filterValues = {
    statusFilter: '',
    roleFilter: '',
    nazwiskoImieFilter: '',
    usernameFilter: '',
    emailFilter: ''
  };
  form: FormGroup;
  roleResultsData: any;
  statusResultsData: any;
  keysRole = Object.keys;
  keysStatus = Object.keys;

  constructor(
    protected userService: UserService,
    protected location: Location,
    protected dialog: MatDialog,
    protected modalConfirmService: ModalService,
    protected fb: FormBuilder,
    private spinnerService: SpinnerService) {
    this.dataSource.filterPredicate = this.tableFilter();
  }


  ngOnInit() {
    this.form = this.fb.group({
      statusFilter: [null],
      roleFilter: [null],
      nazwiskoImieFilter: [null],
      usernameFilter: [null],
      emailFilter: [null]
    });
    this.setFilter();
    this.loadData(this.pageEvent, this.sortBy);

    this.roleResultsData = Object.values(RoleEnum).filter(f => isNaN(Number(f)));
    this.statusResultsData = Object.values(StatusUserEnum).filter(f => isNaN(Number(f)));
  }
  ngAfterViewInit() {
    //this.dataSource.sort = this.sort;
  }
  ngOnDestroy() {
    this._unsubscribe1 ? this._unsubscribe1.unsubscribe() : null;
    this._unsubscribe2 ? this._unsubscribe2.unsubscribe() : null;
    this._unsubscribe3 ? this._unsubscribe3.unsubscribe() : null;
    this._unsubscribe4 ? this._unsubscribe4.unsubscribe() : null;
    this._unsubscribe5 ? this._unsubscribe5.unsubscribe() : null;
    this._unsubscribe6 ? this._unsubscribe6.unsubscribe() : null;
  }

  loadData(event: PageEvent, sortBy: SortBy) {
    this.spinnerService.openSpinner();
    this.setPageEvent(event);
    let body = this.setBodyFindPage(this.pageEvent, sortBy);
    //this.userService.getCollection().subscribe(user => {
    this.userService.findPageGetUsers(body).subscribe(data => {

      let user = [] as User[];
      Object.assign(user, data.content);
      this.pageEvent.length = data.totalElements;
      this.pageEvent.pageIndex = data.pageable.pageNumber;

      this.userService.getRoles().subscribe(role => {
        for (let index = 0; index < user.length; index++) {
          if (user[index].roles.length > 0)
            user[index].role = role.find(r => r.id === user[index].roles[0]).name;
        }
        this.spinnerService.closeSpinner();
      });
      this.dataSource.data = user;
    });
  }

  addNew() {
    const model: UserModal = {
      user: null,
      edit: false,
      show: false,
    };
    this.openDialog(model);
  }

  onEdit(user: User) {
    const model: UserModal = {
      user: user,
      edit: true,
      show: false,
    };
    this.openDialog(model);
  }

  onShow(user: User) {
    const model: UserModal = {
      user: user,
      edit: false,
      show: true,
    };
    this.openDialog(model);
  }

  onDelete(user: User) {
    this._unsubscribe1 = this.modalConfirmService.showDialog('Potwierdzenie', 'Czy chcesz usunąć użytkownika?').subscribe(bol => {
      if (bol) {
        this.userService.deleteUser(user).then(data => {
          this.loadData(this.pageEvent, this.sortBy);
        }, (error: HttpErrorResponse) => {
          if (error.status == 409 && error.url.includes("/User"))
            this.modalConfirmService.showInformDialog("Uwaga", "Usunięcie użytkownika niemożliwe ze względu na powiązania z istniejącymi głosowaniami.");
        });
      }
    });
  }

  openDialog(userModal: UserModal) {
    const dialogRef = this.dialog.open(UserModalComponent, {
      width: '600px',
      data: userModal,
    });

    const sub = dialogRef.componentInstance.onAdd.subscribe(() => {
      this.loadData(this.pageEvent, this.sortBy);
    });

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  setPageEvent(event: PageEvent) {
    this.pageEvent.length = event.length;
    this.pageEvent.pageIndex = event.pageIndex;
    this.pageEvent.pageSize = event.pageSize;
  }
  setBodyFindPage(event: PageEvent, sortBy: SortBy): string {
    let body = `{
      "filter": {`;

    if (this.filterValues.usernameFilter != '') {
      body = body + `
      "username": {
        "entityAtributeType": "String",
        "opertion": "Like",
        "value": "${this.filterValues['usernameFilter']}"
      },`;
    }
    if (this.filterValues.emailFilter != '') {
      body = body + `
      "email": {
        "entityAtributeType": "String",
        "opertion": "Like",
        "value": "${this.filterValues['emailFilter']}"
      },`;
    }
    if (this.filterValues.nazwiskoImieFilter != '') {
      body = body + `
        "name": {
          "entityAtributeType": "String",
          "opertion": "Like",
          "value": "${this.filterValues['nazwiskoImieFilter']}"
        },`;
    }
    if (this.filterValues.roleFilter != '') {
      body = body + `
          "roles2.name": {
            "entityAtributeType": "Enumerator",
            "opertion": "Equal",
            "value": "${this.filterValues['roleFilter']}"
          },`;
    }
    if (this.filterValues.statusFilter != '') {
      body = body + `
          "userStatus": {
            "entityAtributeType": "Enumerator",
            "opertion": "Equal",
            "value": "${this.filterValues['statusFilter']}"
          },`;
    }
    if (this.filterValues.nazwiskoImieFilter != '' || this.filterValues.roleFilter != '' || this.filterValues.statusFilter != '' || this.filterValues.usernameFilter != '' || this.filterValues.emailFilter != '')
      body = body.slice(0, -1);

    body = body +
      `},
          "sort": {
            "${sortBy.active}": "${sortBy.direction}"
          },
        "pageNumber": ${event.pageIndex},
        "pageSize": ${event.pageSize}
      }`;

    // {
    //   "filter": {
    //     "roles2.name": {
    //       "entityAtributeType": "String",
    //       "opertion": "Equal",
    //       "value": "ROLE_USER"
    //     }
    //   },
    //   "sort": {
    //     "name": "ASC",
    //     "userStatus" : "DESC"
    //   },
    //   "pageNumber": 0,
    //   "pageSize": 3
    // }
    return body;
  }
  private sortData(sortField) {
    if (this.sort.direction == "") {
      this.sortBy.direction = "DESC";
      this.sortBy.active = "name";
    } else {
      this.sortBy.direction = this.sort.direction.toUpperCase();
      this.sortBy.active = sortField != undefined ? sortField : this.sort.active;
    }
    this.loadData(this.pageEvent, this.sortBy);
  }

  setRoleOnView(voteResult: string) {
    switch (voteResult) {
      case "ROLE_SUPERADMIN":
        return "Super Admin";
      case "ROLE_ADMIN":
        return "Admin";
      case "ROLE_USER":
        return "User";
      default:
        return "";
    }
  }
  setStatusOnView(voteResult: string) {
    switch (voteResult) {
      case "ACTIVE":
        return "Aktywny";
      case "EXPIRED":
        return "Nie aktywny";
      case "TEST":
        return "Nowy";
      default:
        return "";
    }
  }
  setFilter() {
    this._unsubscribe6 = this.form.controls['usernameFilter'].valueChanges.subscribe(filtrValue => {
      this.filterValues.usernameFilter = filtrValue;
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this._unsubscribe5 = this.form.controls['emailFilter'].valueChanges.subscribe(filtrValue => {
      this.filterValues.emailFilter = filtrValue;
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this._unsubscribe4 = this.form.controls['nazwiskoImieFilter'].valueChanges.subscribe(filtrValue => {
      this.filterValues.nazwiskoImieFilter = filtrValue;
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this._unsubscribe2 = this.form.controls['roleFilter'].valueChanges.subscribe(filtrValue => {
      this.filterValues.roleFilter = filtrValue == "-1" ? '' : RoleEnum[filtrValue];
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this._unsubscribe3 = this.form.controls['statusFilter'].valueChanges.subscribe(filtrValue => {
      this.filterValues.statusFilter = filtrValue == "-1" ? '' : StatusUserEnum[filtrValue];
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }
  tableFilter(): (data: User, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      Object.keys(data).forEach(key => {
        if (data[key] === null) {
          data[key] = '';
        }
      });
      const searchTerms = JSON.parse(filter);

      return data.name.toString().toLowerCase().indexOf(searchTerms.nazwiskoImieFilter.toLowerCase()) !== -1 &&
        data.username.toString().toLowerCase().indexOf(searchTerms.usernameFilter.toLowerCase()) !== -1 &&
        data.email.toString().toLowerCase().indexOf(searchTerms.emailFilter.toLowerCase()) !== -1;
    };
    return filterFunction;
  }

}
