import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './view/unauthenticated/login/login.component';
import { DocumentsComponent } from './view/documents/documents.component';
import { ListUsersComponent } from './view/list-users/list-users.component';
import { DocumentDetailsComponent } from './view/documents/document-details/document-details.component';
import { ChangePasswordComponent } from './view/user-panel/change-password/change-password.component';
import { ResetPasswordComponent } from './view/unauthenticated/login/reset-password/reset-password.component';
import { UnauthenticatedComponent } from './view/unauthenticated/unauthenticated.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    component: UnauthenticatedComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'documents',
    canActivate: [AuthGuard],
    component: DocumentsComponent
  },
  {
    path: 'documents/new',
    canActivate: [AuthGuard],
    component: DocumentDetailsComponent
  },
  {
    path: 'documents/:id',
    canActivate: [AuthGuard],
    component: DocumentDetailsComponent
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    component: ChangePasswordComponent
  },
  {
    path: 'list-users',
    canActivate: [AuthGuard],
    component: ListUsersComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
