import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { ChangePasswordService } from './change-password.service';
import { MatDialog } from '@angular/material';
import { UserModalComponent } from '../../list-users/user-modal/user-modal.component';
import { ChangePasswordOnSuccessComponent } from './change-password-on-success/change-password-on-success.component';
import { ChangePassword } from 'src/app/shared/components/models/changePassword';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(250, style({opacity:1})),        
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({opacity:0})) 
      ])
    ])
  ],
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  returnUrl: string;
  loading = false;

  errMsg: string;

  constructor(protected fb: FormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    private auth: AuthService,
    private changePasswordService: ChangePasswordService
   ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      oldPassword: [null, Validators.required],
      password: [null, [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]],
      passwordRepeat: [null, Validators.required]
    });
  }

  changePassword(): Promise<any> {
    const oldPassword = this.form.value.oldPassword;
    const password = this.form.value.password;
    const passwordRepeat = this.form.value.passwordRepeat;

    return this.changePasswordService.changePassword(oldPassword, password, passwordRepeat)
      .then(response => {
        const dialogRef = this.dialog.open(ChangePasswordOnSuccessComponent);

        dialogRef.afterClosed().subscribe(data => 
          this.auth.logout()
        );
      }, reason => {
        const error = reason.error['message'];
        switch (error) {
          case 'password.TheOldPasswordIsNotMatchedToCurrent': {
            this.errMsg = 'Wprowadzone "Aktualne hasło" jest nieprawidłowe';
            break;
          }
          case 'register.password.not.matched': {
            this.errMsg = 'Błędnie powtórzone nowe hasło';
            break;
          }
          default: {
            console.error(error);
            this.errMsg = error;
          }
        }
      });
  }

  isAnyFieldEmptyAndFormIsInvalid(): boolean {
    let enteredData = Object.values(this.form.getRawValue());
    return enteredData.includes(null) || enteredData.includes("") || !this.form.valid;
  }

  minimumCharactersNumber(passwordLength: number) {
    const password: string = this.form.controls['password'].value;
    return password.length >= passwordLength;
  }

  containsUpperCaseLetter() {
    const password: string = this.form.controls['password'].value;
    return password.search(/[A-Z]/) > -1;
  }

  containsLowerCaseLetter() {
    const password: string = this.form.controls['password'].value;
    return password.search(/[a-z]/) > -1;
  }

  containsDigit() {
    const password: string = this.form.controls['password'].value;
    return password.search(/\d/) > -1;
  }

  containsSpecialCharacter() {
    const password: string = this.form.controls['password'].value;
    return password.search(/[#?!@$%^&*-]/) > -1;
  }
}