import { Component, OnInit, EventEmitter, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { MatTableDataSource, MatSort, PageEvent } from '@angular/material';
import { UserService } from '../../list-users/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModalWindowMode } from 'src/app/shared/enums/modal-window-mode';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DocumentDetailsService } from './document-details.service';
import { Document } from 'src/app/shared/models/document';
import { parseLongDate } from 'src/app/shared/utils/utils';
import { RoleEnum } from 'src/app/shared/enums/role-enum';
import { formatDate } from '@angular/common';
import { StatusEnum } from 'src/app/shared/models/status';
import { ModalService } from 'src/app/shared/services/modal-service/modal.service';
import { TypVote } from 'src/app/shared/models/typVote';
import { Attachement } from 'src/app/shared/components/models/attachment';
import { FileDescription } from 'src/app/shared/components/models/file-description';
import { FormDataAttachementsService } from 'src/app/shared/services/form-data-attachments.service';
import { Entity } from 'src/app/shared/components/models/entity';
import { componentNeedsResolution } from '@angular/core/src/metadata/resource_loading';
import { DependentDocument } from 'src/app/shared/models/dependentDocument';
import { equalSegments } from '@angular/router/src/url_tree';
import { pageSizeOptionsEnumToArray } from 'src/app/shared/pagination/helper-pagination';
import { SortBy } from 'src/app/shared/interfaces/sort-by';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
//@AutoUnsubscribe //?
export class DocumentDetailsComponent implements OnInit {
  status: string = "";
  form: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  public dataSource = new MatTableDataSource<User>();
  public dataSourceNewDocumentsForPerson = new MatTableDataSource<DependentDocument>();
  displayedColumns: string[] = ['selectUserIDToVote', 'name', 'email', 'vote'];
  displayedColumnsNewDocumentsForPerson: string[] = ['name', 'description', 'cmd'];
  listUsers = [] as User[];
  newDocumentsForPerson = [] as DependentDocument[];
  roleLoggedUser: string = "";
  idLoggedUser: string = "";
  selectUserIDToVote: number[] = [];
  deSelectUserIDToVote: number[] = [];
  isSelectAll: boolean = false;
  showBtnVote: boolean = false;
  attachements: Attachement[] = [];
  filesDescription: FileDescription[] = [];

  ModalWindowMode = ModalWindowMode;
  onDataSend = new EventEmitter();

  typeVoteData: TypVote[];
  typeVoteNewData: TypVote[];
  infoVote: string;
  confirmVote: string;

  public filterValues = {
    email: '',
    name: ''
  };

  //pagination
  length = 10;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: Number[] = pageSizeOptionsEnumToArray();
  pageEvent: PageEvent = { pageIndex: 0, pageSize: 1000, length: 0 };//10
  sortBy: SortBy = { direction: 'ASC', active: 'name' };

  private _unsubscribe1: Subscription;
  private _unsubscribe2: Subscription;
  private _unsubscribe3: Subscription;
  private _unsubscribe4: Subscription;
  private _unsubscribe5: Subscription;
  private _unsubscribe6: Subscription;
  private _unsubscribe7: Subscription;
  private _unsubscribe8: Subscription;
  private _unsubscribe9: Subscription;
  private _unsubscribe10: Subscription;
  private _unsubscribe11: Subscription;
  private _unsubscribe12: Subscription;
  private _unsubscribe13: Subscription;
  private _unsubscribe14: Subscription;
  private _unsubscribe15: Subscription;
  private _unsubscribe16: Subscription;
  private _unsubscribe17: Subscription;
  private _unsubscribe18: Subscription;
  private _unsubscribe19: Subscription;
  private _unsubscribe20: Subscription;
  private _unsubscribe21: Subscription;
  private _unsubscribe22: Subscription;
  private _unsubscribe23: Subscription;
  private _unsubscribe24: Subscription;

  constructor(
    protected formDataAttachementsService: FormDataAttachementsService,
    protected userService: UserService,
    protected jwtHelper: JwtHelperService,
    protected fb: FormBuilder,
    private documentService: DocumentDetailsService,
    protected modalConfirmService: ModalService,
    public dialogRef: MatDialogRef<DocumentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentModal,
    private changeDetectorRefs: ChangeDetectorRef,
    private spinnerService: SpinnerService
  ) {
    this.dataSource.filterPredicate = this.tableFilter();
  }

  ngOnInit() {
    this.settypeVoteData();
    this.setTypeVoteNewData();
    this.setLoggedUserRole();
    this.setLoggedUserID();

    this.form = this.fb.group({
      id: [null],
      number: [null, Validators.required],
      name: [null, Validators.required],
      description: [null],
      startDate: [null],
      endDate: [null],
      attachements: [null],
      votingTransparency: [null],
      votingType: [null],
      email: [null],
      nameUser: [null]
    });

    this.setFilter();

    this.status = "new";
    this.form.controls['votingTransparency'].setValue('OPEN');
    this.form.controls['votingType'].setValue('NATURAL');

    switch (this.data.mode) {
      case ModalWindowMode.Edit: {
        this.form.patchValue(this.data.document);
        //this.setDateFormat();
        break;
      }
      case ModalWindowMode.Show: {
        this.form.patchValue(this.data.document);
        this.setDateFormat();
        //this.form.disable();//comment because problem with filter
        break;
      }
      default: {
        break;
      }
    }

    if (this.data.document.procedureStatus != undefined &&
      (StatusEnum[this.data.document.procedureStatus].toString() == StatusEnum.COMPLETED.toString() || (this.roleLoggedUser == 'ROLE_USER'))) {
      this.displayedColumnsNewDocumentsForPerson = ['name', 'description', 'voterYes', 'voterNo', 'voterAbstain'];
    }

    this.loadData(this.pageEvent, this.sortBy);
    this.disableFieldsWhenUserVote();
    this.disableFieldsWhenModeShow();
    this.getInfoVote();
    this.getInfoVoteForPerson();
    this.setColumnVote();
    this.setVotingType();
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  ngOnDestroy() {
    this._unsubscribe1 ? this._unsubscribe1.unsubscribe() : null;
    this._unsubscribe2 ? this._unsubscribe2.unsubscribe() : null;
    this._unsubscribe3 ? this._unsubscribe3.unsubscribe() : null;
    this._unsubscribe4 ? this._unsubscribe4.unsubscribe() : null;
    this._unsubscribe5 ? this._unsubscribe5.unsubscribe() : null;
    this._unsubscribe6 ? this._unsubscribe6.unsubscribe() : null;
    this._unsubscribe7 ? this._unsubscribe7.unsubscribe() : null;
    this._unsubscribe8 ? this._unsubscribe8.unsubscribe() : null;
    this._unsubscribe9 ? this._unsubscribe9.unsubscribe() : null;
    this._unsubscribe10 ? this._unsubscribe10.unsubscribe() : null;
    this._unsubscribe11 ? this._unsubscribe11.unsubscribe() : null;
    this._unsubscribe12 ? this._unsubscribe12.unsubscribe() : null;
    this._unsubscribe13 ? this._unsubscribe13.unsubscribe() : null;
    this._unsubscribe14 ? this._unsubscribe14.unsubscribe() : null;
    this._unsubscribe15 ? this._unsubscribe15.unsubscribe() : null;
    this._unsubscribe16 ? this._unsubscribe16.unsubscribe() : null;
    this._unsubscribe17 ? this._unsubscribe17.unsubscribe() : null;
    this._unsubscribe18 ? this._unsubscribe18.unsubscribe() : null;
    this._unsubscribe19 ? this._unsubscribe19.unsubscribe() : null;
    this._unsubscribe20 ? this._unsubscribe20.unsubscribe() : null;
    this._unsubscribe21 ? this._unsubscribe21.unsubscribe() : null;
    this._unsubscribe22 ? this._unsubscribe22.unsubscribe() : null;
    this._unsubscribe23 ? this._unsubscribe23.unsubscribe() : null;
    this._unsubscribe24 ? this._unsubscribe24.unsubscribe() : null;
  }

  loadData(event: PageEvent, sortBy: SortBy) {
    this.spinnerService.openSpinner();
    this.setPageEvent(event);

    if (this.data.document.id != undefined)
      this._unsubscribe1 = this.documentService.getAttachements(this.data.document.id).subscribe(data =>
        this.attachements = data
      );

    if (this.roleLoggedUser != "ROLE_USER") {
      let arrayUserIdToVote = [] as number[];

      this._unsubscribe2 = this.userService.getRoles().subscribe(role => {
        let idRoleUser = role.find(r => r.name == "ROLE_USER").id;

        let body = this.setBodyFindPage(this.pageEvent, idRoleUser, sortBy);
        //this.userService.getCollection().subscribe(user => {
        this._unsubscribe3 = this.userService.findPageGetUsers(body).subscribe(data => {
          this.listUsers = [];
          Object.assign(this.listUsers, data.content);

          this.pageEvent.length = data.totalElements;
          this.pageEvent.pageIndex = data.pageable.pageNumber;

          let step = 0;
          //this.documentService.getInfoVoteByDocId(this.data.document.id).subscribe(dataVoteInfo => {//problem when is new document
          this._unsubscribe4 = this.documentService.getUsersIdsToDocIdVote().subscribe(dataUsersIdsToDocIdVote => {

            arrayUserIdToVote = dataUsersIdsToDocIdVote.filter(d => d.id.procedureId == this.data.document.id).map(p => p.id.userId) as number[];

            for (let index = 0; index < this.listUsers.length; index++) {
              if (arrayUserIdToVote.includes(this.listUsers[index].id)) {
                this.listUsers[index].selectUser = true;//zaznacza uprawnionych users do glosowania
                this.selectUserIDToVote.push(this.listUsers[index].id);//zapisuje id uprawnionych
              }
              else {
                this.listUsers[index].selectUser = false;
              }
              step++;

              if (this.data.mode == ModalWindowMode.New) {// || this.data.mode == ModalWindowMode.Edit) {
                if (this.selectUserIDToVote.includes(this.listUsers[index].id))
                  this.listUsers[index].selectUser = true;//zaznacza uprawnionych users do glosowania
                // else
                //   this.listUsers[index].selectUser = false;
              }
            }

          }, () => {
            step++;
            if (step === this.listUsers.length) {
              if (this.data.mode == ModalWindowMode.Show) { //ukrywamy uprawnionych którzy nie moga głosować
                this.listUsers = this.listUsers.filter(u => u.selectUser == true);
                //this.pageEvent.length = this.listUsers.length;
              }

              this.setVoteOnView(arrayUserIdToVote);
              this.dataSource.data = this.listUsers;
              this.spinnerService.closeSpinner();
            }
          }, () => {
            if (step === this.listUsers.length) {
              if (this.data.mode == ModalWindowMode.Show) { //ukrywamy uprawnionych którzy nie moga głosować
                this.listUsers = this.listUsers.filter(u => u.selectUser == true);
                //this.pageEvent.length = this.listUsers.length;
              }

              this.setVoteOnView(arrayUserIdToVote);
              this.dataSource.data = this.listUsers;
              this.spinnerService.closeSpinner();
            }
          });

        });
      });
    } else {
      this.dataSource = new MatTableDataSource<User>();
      this.spinnerService.closeSpinner();
    }
  }

  // setVoteOnView(arrayUserIdToVote: number[]) {
  //   if (this.data.document.votingTransparency == "OPEN" && this.data.document.id != undefined) {//jesli glosowanie Jawne to prezentujemy szczegółowe wyniki glosowań
  //     for (let index = 0; index < this.listUsers.length; index++)
  //       if (arrayUserIdToVote.indexOf(this.listUsers[index].id) !== -1)
  //         this.documentService.getInfoVoteUsersIdDocId(this.data.document.id, this.listUsers[index].id.toString()).subscribe(info => {
  //           this.listUsers[index].vote = info != null ? info.vote == "YES" ? "ZA" : info.vote == "NO" ? "PRZECIW" : info.vote == "ABSTAIN" ? "WSTRZYMAŁ SIĘ" : "" : "";
  //         }, (err) => {
  //           //console.log(err);//TEST
  //         });
  //   }
  // }
  setVoteOnView(arrayUserIdToVote: number[]) {//info about user vote
    if (this.data.document.id != undefined && this.roleLoggedUser != "ROLE_USER" &&
      StatusEnum[this.data.document.procedureStatus].toString() == StatusEnum.ACTIVE.toString()) {//this.data.document.procedureStatus == undefined ||

      for (let index = 0; index < this.listUsers.length; index++)
        if (arrayUserIdToVote.indexOf(this.listUsers[index].id) !== -1) {

          let id = "0";
          if (this.data.document.votingType == "FOR_PERSON" && this.data.document.dependents[0] != undefined)
            id = this.data.document.dependents[0].id.toString();
          else
            id = this.data.document.id;

          this._unsubscribe5 = this.documentService.getInfoVoteUsersIdDocId(id, this.listUsers[index].id.toString()).subscribe(info => {
            this.listUsers[index].vote = info.vote != null ? "TAK" : "";
          }, (err) => {
            //console.log(err);//TEST
          });
        }
    }
  }

  changeSelectId(id) {
    let index = this.selectUserIDToVote.indexOf(id);
    if (index !== -1) {
      this.selectUserIDToVote.splice(index, 1);
      this.deSelectUserIDToVote.push(id);
    } else {
      this.selectUserIDToVote.push(id);
      this.deSelectUserIDToVote.splice(index, 1);
    }
    // Object.assign(this.data.document, this.form.value);//TODO when pagination
    // this._unsubscribe18 = this.documentService.update(this.data.document).subscribe(data => {
    //   this.connectionUsersIdsToDocIdVote();
    // });
  }
  selectAll() {
    this.isSelectAll = !this.isSelectAll;
    if (this.isSelectAll) {
      for (let index = 0; index < this.dataSource.data.length; index++) {
        if (this.selectUserIDToVote.includes(this.dataSource.data[index].id) == false)
          this.selectUserIDToVote.push(this.dataSource.data[index].id);
        this.deSelectUserIDToVote = [];
      }
    } else {
      for (let index = 0; index < this.dataSource.data.length; index++)
        this.deSelectUserIDToVote.push(this.dataSource.data[index].id);
      this.selectUserIDToVote = [];
      this.dataSource.data.forEach(row => row.selectUser = false);
    }
    // Object.assign(this.data.document, this.form.value);//TODO when pagination
    // this._unsubscribe18 = this.documentService.update(this.data.document).subscribe(data => {
    //   this.connectionUsersIdsToDocIdVote();
    // });
  }

  setLoggedUserRole() {
    this.roleLoggedUser = this.jwtHelper.decodeToken()['auth'];
  }
  setLoggedUserID() {
    this.idLoggedUser = this.jwtHelper.decodeToken()['sub'];
  }

  setFilter() {
    this._unsubscribe6 = this.form.controls['email'].valueChanges.subscribe(filtrValue => {
      this.filterValues.email = filtrValue;
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
    this._unsubscribe7 = this.form.controls['nameUser'].valueChanges.subscribe(filtrValue => {
      this.filterValues.name = filtrValue;
      this.loadData(this.pageEvent, this.sortBy);
      this.dataSource.filter = JSON.stringify(this.filterValues);
    });
  }
  tableFilter(): (data: User, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      Object.keys(data).forEach(key => {
        if (data[key] === null) {
          data[key] = '';
        }
      });
      const searchTerms = JSON.parse(filter);

      return data.name.toString().toLowerCase().indexOf(searchTerms.name.toLowerCase()) !== -1
        && data.email.toString().toLowerCase().indexOf(searchTerms.email.toLowerCase()) !== -1
    };
    return filterFunction;
  }

  onNew() {
    if (this.checkExistEmptyNameForPerson() == false) {
      Object.assign(this.data.document, this.form.value);
      this.data.document.procedureStatus = StatusEnum.IN_PREPARATION;

      this.setDocumentsForPerson();

      this._unsubscribe15 = this.documentService.create(this.data.document).subscribe(data => {
        this.data.document.id = data.id;//new id
        this.connectionUsersIdsToDocIdVote();

        this.onDataSend.emit();

        const entity: Entity = Object.assign(this.data.document, data, { id: +data.id });

        const formdata = this.formDataAttachementsService.updateFormData(this.filesDescription, entity, this.attachements);
        this._unsubscribe16 = this.documentService.updateWithAtachments(entity.id, formdata).subscribe(data => {
          console.log("PRZESLANE DANE: ", data);
        });

        this.onClose();
      }, (error: HttpErrorResponse) => {
        if (error.status == 409 && error.url.includes("/PdgoProcedure"))
          this.modalConfirmService.showInformDialog("Uwaga", "Istnieje już głosowanie o podanym numerze i nazwie.");
        //console.log(error);
      });
    }
  }

  onEdit() {
    if (this.checkExistEmptyNameForPerson() == false) {
      const entity: Entity = Object.assign(this.data.document, this.form.value);

      this.setDocumentsForPerson();

      const formdata = this.formDataAttachementsService.updateFormData(this.filesDescription, entity, this.attachements);
      const formdata2 = this.formDataAttachementsService.getFormData(this.filesDescription, entity);
      this._unsubscribe17 = this.documentService.updateWithAtachments(entity.id, formdata).subscribe(data => {
        this.connectionUsersIdsToDocIdVote();
      }, null, () => {
        this.onDataSend.emit();
        this.onClose();
      });
      //this.documentService.updateAttachements(formdata2).subscribe(data => { });

      // console.log(formdata);
      // this.data.document.attachments = formdata;
      // this._unsubscribe18 = this.documentService.update(this.data.document).subscribe(data => {
      //   this.connectionUsersIdsToDocIdVote();

      // }, null, () => {
      //   this.onDataSend.emit();
      //   this.onClose();
      // });
    }

  }

  connectionUsersIdsToDocIdVote() {
    for (let index = 0; index < this.selectUserIDToVote.length; index++) {
      const body = `{
          "id": {
            "procedureId": ${this.data.document.id},
            "userId": ${this.selectUserIDToVote[index]}
          }
        }`;//powiazanie dokumentuId z userId ktory bedzie glosowal na ten dok
      this._unsubscribe19 = this.documentService.createUsersIdsToDocIdVote(body).subscribe(res => {
      })
    }
    for (let index = 0; index < this.deSelectUserIDToVote.length; index++) {
      //usuniecie powiazania dokumentuId z userId
      this.documentService.deleteUsersIdsToDocIdVote(this.data.document.id, this.deSelectUserIDToVote[index].toString()).subscribe(res => {
      })
    }
  }

  voteYes() {//USER
    this._unsubscribe8 = this.modalConfirmService.showDialog('Potwierdzenie', 'Czy chcesz oddać głos ZA?').subscribe(bol => {
      if (bol) {
        const body = `{"vote": "YES"}`;
        Object.assign(this.data.document, this.form.value);
        //this.data.document.procedureStatus = StatusEnum.COMPLETED;
        this._unsubscribe20 = this.documentService.vote(this.data.document.id, body).subscribe(resVote => {
          this.onDataSend.emit();
          this.onClose();
          // this.documentService.update(this.data.document).subscribe(data => {

          // });
        });
      }
    });
  }

  voteNo() {//USER
    this._unsubscribe9 = this.modalConfirmService.showDialog('Potwierdzenie', 'Czy chcesz oddać głos PRZECIW?').subscribe(bol => {
      if (bol) {
        const body = `{
          "vote": "NO"
        }`;
        Object.assign(this.data.document, this.form.value);
        //this.data.document.procedureStatus = StatusEnum.COMPLETED;
        this._unsubscribe21 = this.documentService.vote(this.data.document.id, body).subscribe(resVote => {
          this.onDataSend.emit();
          this.onClose();
          // this.documentService.update(this.data.document).subscribe(data => {

          // });
        });
      }
    });

  }

  voteAbstain() {//USER
    this._unsubscribe10 = this.modalConfirmService.showDialog('Potwierdzenie', 'Czy chcesz się wstrzymać od głosowania?').subscribe(bol => {
      if (bol) {
        const body = `{
          "vote": "ABSTAIN"
        }`;
        Object.assign(this.data.document, this.form.value);
        //this.data.document.procedureStatus = StatusEnum.COMPLETED;
        this._unsubscribe22 = this.documentService.vote(this.data.document.id, body).subscribe(resVote => {
          this.onDataSend.emit();
          this.onClose();
          // this.documentService.update(this.data.document).subscribe(data => {

          // });
        });
      }
    });

  }

  getInfoVote() {
    if (this.data.document.id != undefined && this.roleLoggedUser == "ROLE_USER" && this.form.controls['votingType'].value != 'FOR_PERSON')
      this._unsubscribe23 = this.documentService.getInfoVoteUsersIdDocId(this.data.document.id, this.idLoggedUser).subscribe(info => {
        this.infoVote = info.vote != null ? info.vote == "YES" ? "ZA" : info.vote == "NO" ? "PRZECIW" : info.vote == "ABSTAIN" ? "WSTRZYMAŁEM SIĘ" : "" : "";
        this.confirmVote = this.data.document.voteResult == "REJECTED" ? "Odrzucone" : this.data.document.voteResult == "ACCEPTED" ? "Przyjęte" : "";
      });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  parseLongDate(date) {
    return parseLongDate(date);
  }

  setDateFormat() {
    if (this.data.document.startDate == null || this.data.document.startDate.includes("1970-01-01"))
      this.form.controls['startDate'].setValue(null);
    else
      this.form.controls['startDate'].setValue(parseLongDate(this.data.document.startDate));

    if (this.data.document.endDate == null || this.data.document.endDate.includes("1970-01-01"))
      this.form.controls['endDate'].setValue(null);
    else
      this.form.controls['endDate'].setValue(parseLongDate(this.data.document.endDate));
  }

  settypeVoteData() {
    this.typeVoteData = [{
      id: "SECRET",
      name: "TAJNE"
    },
    {
      id: "OPEN",
      name: "JAWNE"
    }];
  }

  //-- new votingType FOR_PERSON, NATURAL
  setTypeVoteNewData() {
    this.typeVoteNewData = [{
      id: "FOR_PERSON",
      name: "OSOBOWE"
    },
    {
      id: "NATURAL",
      name: "ZWYKŁE"
    }];
  }
  changeNewTypVote() {
    if (this.form.controls['votingType'].value == 'FOR_PERSON') {
      this.newDocumentsForPerson = [];
      this.setVotingType();
      //this.dataSourceNewDocumentsForPerson.data = this.newDocumentsForPerson;
    } else {

    }
  }
  addNewVoteForPerson() {
    if (document.getElementById('newDocumentsForPerson') != null)
      if (this.checkExistEmptyNameForPerson() == false) {
        this.newDocumentsForPerson.push(new DependentDocument());
        this.dataSourceNewDocumentsForPerson.data = this.newDocumentsForPerson;
      }
  }
  checkExistEmptyNameForPerson(): boolean {
    if (this.form.controls['votingType'].value == 'FOR_PERSON')
      for (let index = 0; index < document.getElementById('newDocumentsForPerson').children[1].children.length; index++) {
        if ((<HTMLInputElement>document.getElementById('newDocumentsForPerson').children[1].children[index].children[0].children[0]).value == "") {
          this._unsubscribe11 = this.modalConfirmService.showInformDialog('Uwaga', 'Wypełnij pole Nazwiskio i Imię').subscribe();
          return true;
        }
      }
    return false;
  }
  setVotingType() {
    // if (!this.data.document.votingType)
    //   this.form.controls['votingType'].setValue('NATURAL');
    if (this.data.document.votingType == 'FOR_PERSON') {
      for (let index = 0; index < this.data.document.dependents.length; index++) {
        this.newDocumentsForPerson.push(this.data.document.dependents[index]);
        this.dataSourceNewDocumentsForPerson.data = this.newDocumentsForPerson;
        this.changeDetectorRefs.detectChanges();
        (<HTMLInputElement>document.getElementById('newDocumentsForPerson').children[1].children[index].children[0].children[0]).value = this.data.document.dependents[index].name;
        (<HTMLInputElement>document.getElementById('newDocumentsForPerson').children[1].children[index].children[1].children[0]).value = this.data.document.dependents[index].description;
      }
    }
  }
  setDocumentsForPerson() { // set when New or Edit document
    if (document.getElementById('newDocumentsForPerson') != null)
      for (let index = 0; index < document.getElementById('newDocumentsForPerson').children[1].children.length; index++) {
        this.newDocumentsForPerson[index].name = (<HTMLInputElement>document.getElementById('newDocumentsForPerson').children[1].children[index].children[0].children[0]).value;
        this.newDocumentsForPerson[index].description = (<HTMLInputElement>document.getElementById('newDocumentsForPerson').children[1].children[index].children[1].children[0]).value;
        this.newDocumentsForPerson[index].parent = this.data.document.id;
        //this.newDocumentsForPerson[index].id = index;
      }
    //this.data.document.dependents = [];
    this.data.document.dependents = this.newDocumentsForPerson;
  }
  getInfoVoteForPerson() {//only for ROLE_USER
    if (this.data.document.id != undefined && this.data.document.votingType == 'FOR_PERSON' && this.data.mode == ModalWindowMode.Show && this.roleLoggedUser == "ROLE_USER")
      this._unsubscribe24 = this.documentService.getInfoMultiVoteForPerson(this.data.document.id, this.idLoggedUser).subscribe(infoMulti => {

        for (let index = 0; index < this.data.document.dependents.length; index++) {
          if (infoMulti.find(i => i.id.procedureId == this.data.document.dependents[index].id).vote == "YES")
            this.data.document.dependents[index].voterYes = "YES"
          else
            if (infoMulti.find(i => i.id.procedureId == this.data.document.dependents[index].id).vote == "NO")
              this.data.document.dependents[index].voterNo = "NO";
            else
              if (infoMulti.find(i => i.id.procedureId == this.data.document.dependents[index].id).vote == "ABSTAIN")
                this.data.document.dependents[index].voterAbstain = "ABSTAIN";
        }
      });
  }

  changeVote(dependentDocument: DependentDocument, vote: string) {
    for (let index = 0; index < this.data.document.dependents.length; index++) {

      if (this.data.document.dependents[index].id == dependentDocument.id)
        if (vote == 'voterYes') {
          this.data.document.dependents[index].voterYes = "YES";
          this.data.document.dependents[index].voterNo = "";
          this.data.document.dependents[index].voterAbstain = "";
        }
        else
          if (vote == 'voterNo') {
            this.data.document.dependents[index].voterYes = "";
            this.data.document.dependents[index].voterNo = "NO";
            this.data.document.dependents[index].voterAbstain = "";
          }
          else
            if (vote == 'voterAbstain') {
              this.data.document.dependents[index].voterYes = "";
              this.data.document.dependents[index].voterNo = "";
              this.data.document.dependents[index].voterAbstain = "ABSTAIN";
            }
    }

  }
  onDeleteItemForPerson(index: any) {
    this._unsubscribe12 = this.modalConfirmService.showDialog('Potwierdzenie', 'Czy chcesz usunąć pozycję?').subscribe(bol => {
      if (bol) {
        this.setDocumentsForPerson();
        this.newDocumentsForPerson.splice(index, 1);
        this.dataSourceNewDocumentsForPerson.data = this.newDocumentsForPerson;
      }
    });
    //console.log(this.newDocumentsForPerson);
  }

  voteMultiPerson() {//USER
    this._unsubscribe13 = this.modalConfirmService.showDialog('Potwierdzenie', 'Czy chcesz oddać głos?').subscribe(bol => {
      if (bol) {
        Object.assign(this.data.document, this.form.value);
        let body = `{"votes": {`;

        for (let index = 0; index < this.data.document.dependents.length; index++) {

          let vote = "";
          if (this.data.document.dependents[index].voterYes == "YES")
            vote = "YES";
          else
            if (this.data.document.dependents[index].voterNo == "NO")
              vote = "NO";
            else
              if (this.data.document.dependents[index].voterAbstain == "ABSTAIN")
                vote = "ABSTAIN";
              else
                vote = "";

          if (vote == "") {
            this._unsubscribe14 = this.modalConfirmService.showInformDialog('Uwaga', 'Wymagane jest oddanie głosu przy wszystkich kandydatach.').subscribe();
            return;
          }

          body = body + `"${this.data.document.dependents[index].id}": {
            "vote": "${vote}"}`;
          if (index != this.data.document.dependents.length - 1) body = body + `,`
        }
        body = body + `}}`;

        this.documentService.voteMultiPerson(this.data.document.id, body).subscribe(resVote => {
          this.onDataSend.emit();
          this.onClose();
        });
      }
    });
  }
  //END --- new votingType FOR_PERSON, NATURAL

  disableFieldsWhenUserVote() {
    if (this.data.roleLoggedUser == 'ROLE_USER' && this.data.mode == ModalWindowMode.Edit) {
      this.form.controls['number'].disable();
      this.form.controls['name'].disable();
      this.form.controls['description'].disable();
    }
  }
  disableFieldsWhenModeShow() {
    if (this.data.mode == ModalWindowMode.Show) {
      this.form.controls['number'].disable();
      this.form.controls['name'].disable();
      this.form.controls['description'].disable();
      this.form.controls['startDate'].disable();
      this.form.controls['endDate'].disable();
      this.form.controls['votingTransparency'].disable();
      this.form.controls['votingType'].disable();
    } else
      if (this.data.mode == ModalWindowMode.Edit && this.roleLoggedUser == "ROLE_USER") {
        this.form.controls['votingTransparency'].disable();
        this.form.controls['votingType'].disable();
      }
  }

  setColumnVote() {
    if (this.data.document.procedureStatus == undefined || StatusEnum[this.data.document.procedureStatus].toString() == StatusEnum.ACTIVE.toString())//this.data.document.votingTransparency == "OPEN"
      this.displayedColumns = ['selectUserIDToVote', 'name', 'email', 'vote'];
    else
      this.displayedColumns = ['selectUserIDToVote', 'name', 'email'];
  }

  setVoteResultOnView(voteResult: string) {
    switch (voteResult) {
      case "ACCEPTED":
        return "Przyjęte";
      case "REJECTED":
        return "Odrzucone";
      default:
        return "";
    }
  }

  addFile() {

    console.log("add file");
  }

  removeFile() {
    console.log("remove file");
  }

  deleteAttachement(attachement: Attachement) {
    this.documentService.deleteAttachment(+this.data.document.id, attachement).subscribe(result =>
      this.attachements = this.attachements.filter(element => element.id != attachement.id)
    );
  }

  downloadAttachement(attachement: Attachement) {
    this.documentService.getDownloadAttachement(+this.data.document.id, attachement.id);
  }

  setPageEvent(event: PageEvent) {
    this.pageEvent.length = event.length;
    this.pageEvent.pageIndex = event.pageIndex;
    this.pageEvent.pageSize = event.pageSize;
  }
  private sortData(sortField) {
    if (this.sort.direction == "") {
      this.sortBy.direction = "ASC";
      this.sortBy.active = "name";
    } else {
      this.sortBy.direction = this.sort.direction.toUpperCase();
      this.sortBy.active = sortField != undefined ? sortField : this.sort.active;
    }
    this.loadData(this.pageEvent, this.sortBy);
  }
  setBodyFindPage(event: PageEvent, idRoleUser: number, sortBy: SortBy): string {
    let body = `
    {
      "filter": {`;
    if (this.filterValues.name != '') {
      body = body + `"name": {
          "entityAtributeType": "String",
          "opertion": "Like",
          "value": "${this.filterValues['name']}"
        },`;
    }
    if (this.filterValues.email != '') {
      body = body + `"email": {
          "entityAtributeType": "String",
          "opertion": "Like",
          "value": "${this.filterValues['email']}"
        },`;
    }

    body = body + `
          "roles2.id": {
            "entityAtributeType": "Integer",
            "opertion": "Equal",
            "value": ${idRoleUser}
          },
          "userStatus": {
            "entityAtributeType": "Enumerator",
            "opertion": "Equal",
            "value": "ACTIVE"
          }
        },
        "sort": {
          "${sortBy.active}": "${sortBy.direction}"
        },
      "pageNumber": ${event.pageIndex},
      "pageSize": ${event.pageSize}
    }`;
    //  {
    // "filter": {
    //   "additionalProp1": {
    //     "entityAtributeType": "Integer",
    //     "opertion": "Equal",
    //     "value": {}
    //   },
    //   "additionalProp2": {
    //     "entityAtributeType": "Integer",
    //     "opertion": "Equal",
    //     "value": {}
    //   },
    //   "additionalProp3": {
    //     "entityAtributeType": "Integer",
    //     "opertion": "Equal",
    //     "value": {}
    //   }
    // },
    //   "page": {
    //     "sort": {
    //       "unsorted": true,
    //       "sorted": true,
    //       "empty": true
    //     },
    //     "offset": 0,
    //     "pageNumber": 0,
    //     "pageSize": 0,
    //     "unpaged": true,
    //     "paged": true
    //   }
    //}

    return body;
  }

}

export class DocumentModal {
  document: Document;
  mode: ModalWindowMode;
  roleLoggedUser: string;//RoleEnum;
  showBtnVote: boolean;
}


