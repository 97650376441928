import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { fromEvent, Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material';
import { AutoLogOutModalComponent } from './shared/components/auto-log-out-modal/auto-log-out-modal.component';
import { AutoLogoutIntervalService } from './shared/components/auto-log-out-modal/auto-logout-interval.service';
import { JwtExpiredInfoComponent } from './shared/components/jwt-expired-info/jwt-expired-info.component';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  timeout: number = environment.inactivityTimeoutInMinutes * 1000 * 60;
  timer: ReturnType<typeof setTimeout>;
  intervaler: ReturnType<typeof setInterval>;

  readonly events: string[] = ["click", "keydown", "mousemove"];
  subscriptions: Subscription[] = [];

  constructor(private auth: AuthService,
    private autoLogoutIntervalService: AutoLogoutIntervalService,
    protected dialog: MatDialog) { 

      this.init();
  }

  init() {    
    if (this.timer) {
      this.clearTimer();
    }

    let waitForAuthentication = new Promise((resolve, reject) => {
      this.intervaler = setInterval(() => {
        if (this.auth.isLoggedIn()) {
          resolve(true);
        }
      }, 1000);
    })

    waitForAuthentication.then(() => {
      // reset intervaler pulling isLoggedIn()
      clearInterval(this.intervaler);
      // start timer of inactivity
      this.timer = this.setTimer();  
      // add eventListeners
      this.events.forEach(event => 
        this.subscriptions.push(fromEvent(document, event)
          .subscribe(event => {
            this.resetTimer();
          })
        )
      );
      // add promise on JWT expiration time
      const jwtExpirationTimeout = this.auth.getExpirationDate().valueOf() - new Date().valueOf() - 1000;
      setTimeout(() => {
        this.clearTimer();
        const dialogRef = this.dialog.open(JwtExpiredInfoComponent);    
        dialogRef.afterClosed().subscribe(() => {
          this.auth.logout();
        });
      }, jwtExpirationTimeout);
    });
  }

  setTimer() {
    return this.timer = setTimeout(() => 
      this.timerAction(), this.timeout
    );  
  }

  clearTimer() {
    clearTimeout(this.timer);
  }

  resetTimer() {
    this.clearTimer();
    this.setTimer();
  }


  timerAction() {
    if (this.auth.isLoggedIn()) {
      const dialogRef = this.dialog.open(AutoLogOutModalComponent);    
      dialogRef.afterClosed().subscribe(() => {
        this.autoLogoutIntervalService.stopCountingDown();
      });
    }
  }
}
