import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterService } from './register.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatDialog } from '@angular/material';
import { RegisterOnSuccessComponent } from './register-on-success/register-on-success.component';
import { trigger, style, animate, transition } from '@angular/animations';
import { ReCaptchaService } from '../re-captcha.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(250, style({opacity:1})),        
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(250, style({opacity:0})) 
      ])
    ])
  ],
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  
  form: FormGroup;

  // TODO register new reCaptchaKey
  siteKeyReCaptcha: string = environment.siteKeyReCaptcha; //localhost
  reCaptchaFailed: boolean = true;

  // dwa atrybuty do usuniecia
  returnUrl: string;
  loading = false;

  errMsg: string = "";

  constructor(protected fb: FormBuilder,
    protected router: Router,
    protected dialog: MatDialog,
    private registerService: RegisterService,
    private reCaptchaService: ReCaptchaService
   ) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      login: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)]],
      passwordRepeat: [null, [Validators.required]],
      companyId: 1
    });
  }

  register() {
    const argument = this.form.value;
    argument['name'] = this.form.value['firstName'] + ' ' + this.form.value['lastName'];
    this.registerService.resetPassword(this.form.value).then(
      result => {
        const dialogRef = this.dialog.open(RegisterOnSuccessComponent);
        dialogRef.afterClosed().subscribe(() => location.reload());        
      },
      reason => {
        const error = reason.error['message'];
        const errors = reason.error['errors'];
        if (errors) {
          if (errors[0]['defaultMessage'] == "must be a well-formed email address") {
            this.errMsg = "Wprowadzono adres e-mail w nieprawidłowym formacie";
          }
          else {
            this.errMsg = "";
            errors.forEach(error => {
              this.errMsg += error['defaultMessage'] + "; ";
            });
          }
        }
        else {
          if (error == "register.password.not.matched") {
            this.errMsg = "Błędnie powtórzone hasło"
          }
          else {
            this.errMsg = error;
          }
        }
      }
    );
  }

  isAnyFieldEmptyOrFormInvalid(): boolean {
    let enteredData = Object.values(this.form.getRawValue());
    const isAnyFieldEmpty = enteredData.includes(null) || enteredData.includes("");
    const isFormInvalid = !this.form.valid;
    let result = isAnyFieldEmpty || isFormInvalid || this.reCaptchaFailed;
    return result;
  }

  minimumCharactersNumber(passwordLength: number) {
    const password: string = this.form.controls['password'].value;
    return password.length >= passwordLength;
  }

  containsUpperCaseLetter() {
    const password: string = this.form.controls['password'].value;
    return password.search(/[A-Z]/) > -1;
  }

  containsLowerCaseLetter() {
    const password: string = this.form.controls['password'].value;
    return password.search(/[a-z]/) > -1;
  }
  
  containsDigit() {
    const password: string = this.form.controls['password'].value;
    return password.search(/\d/) > -1;
  }

  containsSpecialCharacter() {
    const password: string = this.form.controls['password'].value;
    return password.search(/[#?!@$%^&*-]/) > -1;
  }

  async resolved(reCaptchaResponse) {
    await this.sendTokenToBackend(reCaptchaResponse);
  }
  
  sendTokenToBackend(token){
    //calling the service and passing the token to the service
    this.reCaptchaService.sendToken(token).subscribe(
      data => {
        this.reCaptchaFailed = false;
      },
      err => {
        this.reCaptchaFailed = true;
      },
      () => {}
    );
  }
}
