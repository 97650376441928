import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-inform',
  templateUrl: './modal-inform.component.html',
  styleUrls: ['./modal-inform.component.scss']
})
export class ModalInformComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalInformComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, content: string}
  ) { }

  ngOnInit() {
  }
}
