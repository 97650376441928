import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { InactivityService } from './inactivity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Dokumenty-WB';
  //isLoggedIn: boolean = false;

  constructor(
    private inactivityService: InactivityService,
    protected router: Router,
    protected authService: AuthService,
    public jwtHelperService: JwtHelperService) { }

  ngOnInit() {
  }

  logOut() {
    this.authService.logout();
  }

  hasAdminRole() {
    const userRole: string = this.jwtHelperService.decodeToken()['auth'];
    return userRole.toUpperCase().includes("ADMIN");
  }

  jwtName() {
    return this.jwtHelperService.decodeToken()['name']
  }

  jwtAuth() {
    return this.jwtHelperService.decodeToken()['auth']
  }
}
